import EventEmitter from "events";
import { DependencyList, useCallback, useEffect, useState } from "react";
import { useMapping } from "./use-mapping";

type AsyncExtras<A> = {
  setValue: (x: A) => void;
  trigger: (force?: boolean) => void;
}

export type AsyncLoading<A> = {
  loading: true;
  value: undefined;
  error: undefined;
  runAt: undefined;
}

export type AsyncError<A> = {
  loading: false;
  value: undefined;
  error: Error;
  runAt: number;
}

export type AsyncValue<A> = {
  loading: false;
  value: A;
  error: undefined;
  runAt: number;
}

export type AsyncResult<A> =
  | AsyncLoading<A>
  | AsyncError<A>
  | AsyncValue<A>;

export type Async<A> = AsyncExtras<A> & AsyncResult<A>;

export type AsyncArgs<A,> = {
  delay?: number;
  key?: string;
  debounce?: boolean;
} | {
  defer: boolean;
  default: A;
  delay?: number;
  key?: string;
  debounce?: boolean;
}

const refresh = new EventEmitter();

export const useAsync = <A,>(binding: () => Promise<A>, deps?: DependencyList, args: AsyncArgs<A>={}): Async<A> => {
  // const [ state, setState ] = useState<AsyncResult<A>>(() => ({
  //   loading: true,
  // } as AsyncLoading<A>));
  const [ state, setState ] = useState<AsyncResult<A>>(() => {
    if ('defer' in args && args.defer) {
      return {
        value: args.default,
      } as AsyncValue<A>;
    }

    return {
      loading: true,
    } as AsyncLoading<A>;
  });

  const setValue = (value: A) => {
    setState(() => ({
      loading: false,
      runAt: new Date().getTime(),
      value,
    } as AsyncValue<A>))
  };

  const perform = async () => {
    const now = new Date().getTime();
    setState(state => {
      return {
        loading: true,
      } as AsyncLoading<A>;
    });

    try {
      const value = await binding();
      setState(state => {
        return {
          runAt: now,
          loading: false,
          error: undefined,
          value: value,
        } as AsyncValue<A>;
      })
    }
    catch (error: any) {
      setState(state => {
        return {
          loading: false,
          error: error as Error,
        } as AsyncError<A>;
      })
    }
  }

  const trigger = useCallback((force?: boolean) => {
    if (args?.delay && args.debounce) {
      const now = new Date().getTime();
      if (state.runAt !== undefined) {
        const timeDelta = now - state.runAt;
        if (timeDelta < args.delay) {
          if (!force) return;
        }
      }
    }

    if (args?.key) {
      refresh.emit(args.key, null);
    }
    else {
      perform();
    }
  }, [ perform, state.runAt, args ]);

  useEffect(() => {
    if (!args?.key) return;
    const key = args.key;

    refresh.addListener(key , perform);
    return () => {
      refresh.removeListener(key, perform);
    }
  }, [ ...deps || [] ]);

  useEffect(() => {
    if (args?.delay) {
      const timer = setTimeout(() => {
        trigger();
      }, args.delay);

      return () => clearTimeout(timer);
    }

    if ('defer' in args && args.defer) {
      return;
    }
    perform();
    // setState(state => ({
    //   loading: true,
    // } as AsyncLoading<A>));
  }, [ ...(deps || []) ]);

  useEffect(() => {
    if ('defer' in args && args.defer) return;
    // perform();
  }, []);

  return {
    ...state,
    trigger,
    setValue,
  }
}

export const triggerAsync = (key: string) => {
  refresh.emit(key, null);
}