import { Async, useMapping } from "../../hooks"
import { Loading } from "../../ui/control/loading";
import { Icon } from "../../ui/imagery/Icon";
import { Text } from "../../ui";
import { Button } from "../../ui/interactive/button";

export const ShowApprovedButton = ({
  onClick,
  active,
}: {
  onClick: () => void;
  active: boolean;
}) => {
  const backgroundColor = useMapping(() => {
    if (!active) return 'white';
    return 'lightgrey';
  }, [ active ]);

  return <Button onClick={onClick}
    style={{ display: 'flex', alignItems: 'center', backgroundColor, }}
  >
    <Text>Show Approved</Text>
  </Button>
}