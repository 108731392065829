import { DependencyList, useEffect, useState } from "react"

export const useMapping = <A,>(mapping: () => A, deps: DependencyList | undefined) => {
  const [ value, setValue ] = useState(() => mapping());

  useEffect(() => {
    setValue(() => mapping());
  }, deps);

  return value;
}