import { useState } from "react";
import { Async, useAsync, useHttp, useMapping } from "../../hooks";
import { Resume } from "../../io/resumes-api";
import { uploadImage } from "../../io/files-api";
import { Panel } from "../../ui/layout/Panel";
import { Image } from "../../ui";
import { TitlePanel } from "./title-panel";
import { Button } from "../../ui/interactive/button";
import { FileCard } from "../cards/file-card";

const ResumeImage = ({
  file,
  resumeId,
}: {
  file?: File;
  resumeId: string;
}) => {
  const image = useMapping(() => {
    if (file) return <FileCard file={file} />
    return <Image src={`/api/v1/resumes/${resumeId}/image`} />
  }, [ file, resumeId ]);

  return <div style={{ width: '100%', height: '100%' }}>
    {image}
  </div>
}

export const EditResumeImagePanel = ({
  resume,
}: {
  resume: Resume;
}) => {
  const http = useHttp();
  const [ file, setFile ] = useState<File | undefined>();

  const saveFn = useAsync(async () => {
    if (!file) return null;

    return await uploadImage(
      http.client,
      `/api/v1/resumes/${resume.id}/image`,
      file,
      1280,
    );
  }, [ file ], {
    defer: true,
    default: null,
  });

  return <TitlePanel title={"Image"}>
      <input type='file' onChange={e => setFile(e.target?.files && e.target.files[0] || undefined)} />
      <Panel style={{ width: '30rem', height: '30rem' }}>
        <ResumeImage file={file} resumeId={resume.id} />
      </Panel>
      <Button onClick={() => saveFn.trigger()}>Upload</Button>
  </TitlePanel>
}