import styles from './Panel.module.scss';
import clsx from 'clsx';

export type PanelProps = React.HTMLAttributes<HTMLElement> & {
  fill?: boolean;
};

export const Panel = ({
  className,
  fill,
  ...props
}: PanelProps) => {
  return <div
    {...props}
    className={clsx(
      className,
      styles.panel,
      fill && styles.fill,
    )}
  />
}