import { useAsync, useErrors, useMapping } from '../../hooks';
import { Document, DocumentStatus, useDocumentsApi } from '../../io/documents-api';
import { Image, Text } from '../../ui';
import { Loading } from '../../ui/control/loading';
import { Button } from '../../ui/interactive/button';
import { Panel } from '../../ui/layout/Panel';
import { FileCard } from '../cards/file-card';
import { ReferenceCompactView } from '../references/reference-compact-view';

const DocumentPreview = ({
  file,
  documentId,
}: {
  file?: File;
  documentId: string;
}) => {
  const image = useMapping(() => {
    if (file) return <FileCard file={file} />
    return <Image src={`/api/v1/documents/${documentId}/preview`} />
  }, [ file, documentId ]);

  return <div style={{ width: '100%', height: '20rem', display: 'flex', alignItems: 'center' }}>
    {image}
  </div>
}

export const ApproveDocumentCard = ({
  document,
}: {
  document: Document,
}) => {
  const errors = useErrors();
  const documentsApi = useDocumentsApi();
  const approve = useAsync(async () => documentsApi.approve(document.id), [ document.id ], {
    defer: true,
    default: null,
  });
  const decline = useAsync(async () => documentsApi.decline(document.id), [ document.id ], {
    defer: true,
    default: null,
  });

  const approveButton = useMapping(() => {
    if (approve.loading) return <Loading />
    if (approve.error) return errors.onError(approve.error);

    if (document.status === DocumentStatus.Approved) {
      return <Text center>Document has already been approved.</Text>
    }
    return <Button onClick={() => approve.trigger(true)}>Approve</Button>
  }, [ document.id, approve.loading, approve.error, document.status ]);

  const declineButton = useMapping(() => {
    if (decline.loading) return <Loading />
    if (decline.error) return errors.onError(decline.error);

    if (document.status === DocumentStatus.Declined) {
      return <Text center>Document has already been declined.</Text>
    }
    return <Button onClick={() => decline.trigger(true)}>Decline</Button>
  }, [ document.id, decline.loading, decline.error, document.status ]);

  return <Panel key={`document-${document.id}`} style={{ width: '100%', maxWidth: '40rem' }}>
    <div style={{ margin: '1rem' }}>
      <DocumentPreview documentId={document.id} />
      <Panel style={{ height: '2rem', display: 'flex', justifyContent: 'center' }}>
        <Text center capitalize>Skill: {document.skills?.join(', ')}</Text>
      </Panel>
      <Panel style={{ minHeight: '2rem', padding: '10px', display: 'flex', justifyContent: 'center' }}>
        <Text paragraph center>{document.description}</Text>
      </Panel>
      <div style={{ marginTop: '1rem' }} />
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        {approveButton}
        {declineButton}
      </div>
    </div>
  </Panel>
}