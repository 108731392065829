import { Async, useMapping } from "../../hooks"
import { Loading } from "../../ui/control/loading";
import { Button } from "../../ui/interactive/button";

export const SaveButton = ({
  task,
  disabled=false,
}: {
  task: Async<any>;
  disabled?: boolean;
}) => {
  const onClick = () => {
    if (task.loading) return;
    task.trigger(false);
  }

  const content = useMapping(() => {
    if (task.loading) return <Loading />

    return <>Save</>
  }, [ task.loading, task.error ]);

  return <Button disabled={disabled} onClick={onClick}>
    {content}
  </Button>
}