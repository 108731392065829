import { Link, useParams } from "react-router-dom"
import { Page } from "../../ui/interactive/page"
import { useDocuments, useDocumentsApi, Document, DocumentStatus } from "../../io/documents-api";
import { useAsync, useErrors, useMapping } from "../../hooks";
import { Loading } from "../../ui/control/loading";
import { Image, Card, Grid, Text } from "../../ui";
import { Button } from "../../ui/interactive/button";
import { Panel } from "../../ui/layout/Panel";
import { ApproveDocumentCard } from "../documents/approve-document-card";
import { useFindResume, useResume, useResumes } from "../../io/resumes-api";

const DocumentCard = ({
  document,
}: {
  document: Document;
}) => {
  return <ApproveDocumentCard document={document} />
}

export const ReferenceReviewPage = () => {
  const {
    referenceId,
  } = useParams();
  const errors = useErrors();
  const documents = useDocuments({
    referenceId,
    statusIn: [
      DocumentStatus.PendingReview,
    ],
  });

  const resume = useFindResume({
    referenceId,
  });

  const continueButton = useMapping(() => {
    if (!resume.value) return <Button>
      <Loading />
    </Button>

    return <Link to={`/${resume.value.slug}`}>
      <Button>
        {resume.value.contactName}'s Resume
      </Button>
    </Link>
  }, [ resume.value ]);

  const documentViews = useMapping(() => {
    if (documents.loading) return <Loading />
    if (documents.error) return errors.onError(documents.error);

    const docs = 
      documents.value
      .filter(x => [
        DocumentStatus.PendingReview
      ].includes(x.status));

    if (docs.length === 0) {
      return <>
        <div style={{ margin: '1rem' }} />
        <Text center>All documents have been reviewed.</Text>
        <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '3rem', justifyContent: 'center' }}>
          {continueButton}
        </div>
      </>
    }

    const docViews = docs
      .map(document => {
        return <DocumentCard document={document} />
      });

    return <>
      <div style={{ margin: '1rem' }} />
      <Text center>Please review the following documents.</Text>
      <div style={{ margin: '1rem' }} />
      <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '3rem', justifyContent: 'center' }}>
        {docViews}
      </div>
    </>
  }, [ documents.loading, documents.error, documents.value, continueButton ]);

  return <Page>
    <Panel>
      {documentViews}
    </Panel>
  </Page>
}