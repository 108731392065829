import { ReactNode } from "react";
import { Panel } from "../../ui/layout/Panel";
import { Text, FontSize } from '../../ui';
import styles from './panels.module.scss';
import { HorizontalBreak } from "../../ui/layout/horizontal-break";

export const TitlePanel = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return <Panel className={styles.panel}>
    <div className={styles.header}>
      <Text className={styles.title} center fontSize={FontSize.Large} bold>{title}</Text>
      <HorizontalBreak />
    </div>
    <div className={styles.content}>
      {children}
    </div>
  </Panel>
}