import { ReactNode, useEffect, useState } from "react"
import styles from './toast.module.scss';
import { useTimer } from "../../hooks/use-timer";
import clsx from 'clsx';
import { useMapping } from "../../hooks";

export const Toast = ({
  children,
  show,
}: {
  children: ReactNode;
  show: boolean;
}) => {
  const [ hidden, setHidden ] = useState(() => true);

  useEffect(() => {
    if (!show) return;

    setHidden(false);
    setTimeout(() => setHidden(true), 3000);
  }, [ show ]);

  return <div className={clsx(styles.toast, !hidden && styles.show)}>
    {children}
  </div>
}