import { Image } from '../../ui';
import styles from './resumes.module.scss';

export const ResumeImage = ({
  src,
}: {
  src: string;
}) => {
  return <div className={styles.resumeImageContainer}>
    <Image style={{ objectFit: 'cover' }} shape='round' className={styles.resumeImage} src={src} />
  </div>
}