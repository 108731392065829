import { Link } from "react-router-dom"
import { useAuth, useMapping, useQueryParam } from "../../hooks"
import { Grid, Text } from "../../ui"
import { Button } from "../../ui/interactive/button"
import { Page } from "../../ui/interactive/page"
import { Panel } from "../../ui/layout/Panel"
import { IndustriesAdminSection } from "../industries/industries-admin-section"
import { ResumeGrid } from "../resumes/resume-grid"
import { Loading } from "../../ui/control/loading"
import { ResumeSearchSection } from "../resumes/resume-search-section"

enum Tab {
  Resumes = 'resumes',
  Industries = 'industries',
}

export const AdminHomePage = () => {
  const {
    value: tab,
    setParam: setTab,
  } = useQueryParam<Tab>('tab', x => {
    if (x === null) return Tab.Resumes;
    if (!Object.values(Tab).includes(x as Tab)) return Tab.Resumes;
    return x as Tab;
  });

  const tabView = useMapping(() => {
    switch (tab) {
      case Tab.Resumes:
        return <>
          <div>
            <Link to='/create/resume'>
              <Button>New</Button>
            </Link>
          </div>
          <ResumeSearchSection />
        </>

      case Tab.Industries:
        return <IndustriesAdminSection />
    }
  }, [ tab ]);

  return <Page>
    <Panel style={{ width: '100%', overflowX: 'scroll' }}>
      <div style={{ width: 'max-content' }} >
        <Button disabled={tab === Tab.Resumes} onClick={() => setTab(Tab.Resumes)}>Resumes</Button>
        <Button disabled={tab === Tab.Industries} onClick={() => setTab(Tab.Industries)}>Industries</Button>
      </div>
    </Panel>
    {tabView}
  </Page>
}

const DefaultHomePage = () => {
  return <Page>
    <Text center>Under Construction</Text>
  </Page>
}

export const HomePage = () => {
  const auth = useAuth();

  return useMapping(() => {
    if (!auth.user) {
      return <Loading />
    }
    else if (auth.user.roles?.includes('admin')) {
      return <AdminHomePage />
    }
    else {
      return <DefaultHomePage />
    }
  }, [ auth.user ]);
}