import { useState } from "react";
import { useAsync, useHttp, useMapping } from "../../hooks";
import { VerifiedReference } from "../../io/references-api";
import { Resume } from "../../io/resumes-api";
import { FontSize, Grid, Text, Image } from '../../ui';
import { Icon } from "../../ui/imagery/Icon";
import { TextInput } from "../../ui/input/TextInput";
import { Button } from "../../ui/interactive/button";
import { Panel } from "../../ui/layout/Panel";
import { ResumeImage } from "./resume-image";
import styles from './resumes.module.scss';
import { DocumentStatus, useDocuments } from "../../io/documents-api";
import { Modal } from "../../ui/modals/modal";

const ContactModalContent = ({
  resume,
}: {
  resume: Resume;
}) => {
  const urlElems = useMapping(() => {
    const elems: JSX.Element[] = [];

    if (resume.linkedInUrl) {
      elems.push(<li style={{ margin: '1rem' }}>
        <a target='_blank' href={resume.linkedInUrl}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '1.5rem', height: '1.5rem' }}>
              <Image src='/linkedin-link-icon.png' />
            </div>
            <Text style={{ marginLeft: '.5rem', textOverflow: 'ellipsis', alignSelf: 'center', width: '100%', clear: 'both', whiteSpace: 'nowrap' }}>
              LinkedIn
            </Text>
          </div>
        </a>
      </li>);
    }
    if (resume.personalUrl) {
      elems.push(<li style={{ margin: '1rem' }}>
        <a target='_blank' href={resume.personalUrl}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '1.5rem', height: '1.5rem' }}>
              <Image src='/pdf-link-icon.png' />
            </div>
            <Text style={{ marginLeft: '.5rem', textOverflow: 'ellipsis', alignSelf: 'center', width: '100%', clear: 'both', whiteSpace: 'nowrap' }}>
              Download PDF
            </Text>
          </div>
        </a>
      </li>);
    }

    return elems;
  }, [ resume ]);

  return <Panel style={{ minHeight: '10rem' }}>
    <div style={{ margin: '1rem' }}>
      <Text center capitalize bold fontSize={FontSize.Large}>{resume.contactName}</Text>
    </div>
    <Panel>
      <ul style={{ width: 'max-content' }}>
        {urlElems}
      </ul>
    </Panel>
    <div style={{ margin: '1rem' }}>
      <TextInput area placeholder={`Send ${resume.contactName} a message`} />
    </div>
    <Grid center>
      <Button>Send Message</Button>
    </Grid>
  </Panel>
}

export const ResumeHeader = ({
  resume,
  selectedReference,
  search,
  setSearch,
}: {
  resume: Resume;
  selectedReference: boolean;
  search: string;
  setSearch: (x: string) => void;
}) => {
  const [ showContact, setShowContact ] = useState(false);

  const documents = useDocuments({
    resumeId: resume.id,
    statusIn: [
      DocumentStatus.PendingReview,
      DocumentStatus.Approved,
    ],
  });

  const documentCount = useMapping(() => {
    if (!documents.value) return '';

    const approvedCount = documents.value.filter(x => x.status === DocumentStatus.Approved).length;
    const totalCount = documents.value.length;
    return `${approvedCount} / ${totalCount}`;
  }, [ documents.value ]);

  const urlElems = useMapping(() => {
    const elems: JSX.Element[] = [];

    if (resume.linkedInUrl) {
      elems.push(<li style={{ margin: '1rem' }}>
        <a target='_blank' href={resume.linkedInUrl}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '1.5rem', height: '1.5rem' }}>
              <Image src='/linkedin-link-icon.png' />
            </div>
            <Text style={{ marginLeft: '.5rem', textOverflow: 'ellipsis', alignSelf: 'center', width: '100%', clear: 'both', whiteSpace: 'nowrap' }}>
              LinkedIn
            </Text>
          </div>
        </a>
      </li>);
    }
    if (resume.personalUrl) {
      elems.push(<li style={{ margin: '1rem' }}>
        <a target='_blank' href={resume.personalUrl}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '1.5rem', height: '1.5rem' }}>
              <Image src='/pdf-link-icon.png' />
            </div>
            <Text style={{ marginLeft: '.5rem', textOverflow: 'ellipsis', alignSelf: 'center', width: '100%', clear: 'both', whiteSpace: 'nowrap' }}>
              Download PDF
            </Text>
          </div>
        </a>
      </li>);
    }

    return elems;
  }, [ resume ]);

  return useMapping(() => {
    return <Panel className={styles.headerSection}>
      <div key='resume-header' className={styles.resumeHeader}>
        <div className={styles.resumeInfo}>
          <ResumeImage src={`/api/v1/resumes/${resume.id}/image`} />
          <Text center fontSize={FontSize.Large} className={styles.resumeName}>{resume.contactName}</Text>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.verifiedBlock}>
          <Icon icon='verified' style={{ color: '#03a9fc', fontSize: '20px' }} />
          <Text center fontSize={FontSize.Large}>
            Images {documentCount}
            </Text>
        </div>
        <div className={styles.headerUrls}>
          {urlElems}
        </div>
        <TextInput value={search} setValue={setSearch} placeholder='Search Documents' />
      </div>
      <Modal enabled={showContact} onClose={() => setShowContact(false)}>
        <ContactModalContent resume={resume} />
      </Modal>
    </Panel>
  }, [ resume, documentCount, showContact, selectedReference ])
}