import { NavigateOptions, useLocation, useNavigate } from "react-router-dom"
import { useMapping } from "./use-mapping";
import { useState } from "react";

export type QueryParamState<A, B> = {
  value: A;
  setParam: (x: B | null) => void;
  params: URLSearchParams;
}
export const useQueryParam = <A=string, B=A>(name: string, mapping: (x: string | null) => A, options: NavigateOptions={}): QueryParamState<A, B> => {
  const location = useLocation();
  const nav = useNavigate();

  const state = useMapping(() => {
    const query = new URLSearchParams(location.search);
    const params = (() => {
      try {
        return new URLSearchParams(atob(query.get('q') || ''));
      }
      catch {
        return new URLSearchParams();
      }
    })();
    const value = (() => {
      if (params.has(name)) return params.get(name);
      return query.get(name);
    })();

    return {
      params,
      value: mapping(value) || value as A,
    };
  }, [ location.search ]);

  return {
    ...state,
    setParam: (value: B | null) => {
      if (value === null) {
        state.params.delete(name);
      }
      else if (typeof value === 'object') {
        state.params.set(name, JSON.stringify(value));
      }
      else {
        state.params.set(name, value as string);
      }
      const query = new URLSearchParams();
      query.set('q', btoa(state.params.toString()));

      nav('?' + query.toString(), options);
    },
  };
}