import { useEffect, useState } from "react";
import { useErrors, useMapping } from "../../hooks";
import { Skill, SkillQueryParams, useSkills } from "../../io/skills-api";
import { Text } from "../../ui";
import { Loading } from "../../ui/control/loading";
import { Button } from "../../ui/interactive/button";
import { Panel } from "../../ui/layout/Panel";
import { Modal } from "../../ui/modals/modal";

export const SkillSelection = ({
  query,
  tags,
  onSelected,
}: {
  query: SkillQueryParams;  
  tags?: string[];
  onSelected: (x: string[]) => void;
}) => {
  const [ show, setShow ] = useState(false);
  const [ values, setValues ] = useState<string[]>(tags || []);

  useEffect(() => {
    setValues(tags || []);
  }, [ tags ]);

  const errors = useErrors();
  const skills = useSkills(query);
  const options = useMapping(() => {
    if (skills.loading) return <Loading />
    if (skills.error) return errors.onError(skills.error);

    const clicked = (ref: Skill) => {
      onSelected([ ref.tag ]);
      setShow(false);
    }

    return skills.value.map(skill => {
      return <Panel style={{ height: '3rem' }} onClick={() => clicked(skill)}>
        <Button flat style={{ display: 'flex', width: '100%', height: '100%', margin: 0, alignItems: 'center' }}>
          <Text capitalize>
            {skill.tag}
          </Text>
        </Button>
      </Panel>
    });
  }, [ tags, query, values, skills.loading, skills.value ]);

  const selectionElem = useMapping(() => {
    if (!values || values.length === 0) {
      return <Text center>(Select skills)</Text>
    }
    return <Text capitalize center>{values.join(', ')}</Text>
  }, [ values ]);

  const onClose = () => {
    onSelected(values);
    setShow(false);
  }

  return <>
    <Panel style={{ marginTop: '1rem'  }}>
      <Button flat style={{ margin: 0, width: '100%', height: '100%' }} onClick={() => setShow(true)}>
        {selectionElem}
      </Button>
    </Panel>
    <Modal enabled={show} onClose={onClose}>
      <Panel>
        <div style={{ marginTop: '1rem' }} />
        <Text bold center>Select Skill</Text>
        <div style={{ margin: '1rem' }} />
        {options}
      </Panel>
    </Modal>
  </>
}