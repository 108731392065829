import React, { KeyboardEvent, ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './input.module.scss';
import { useMapping } from '../../hooks';
import { FontSize, Text } from '../typography';

export type TextInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  setValue?: (x: string) => void;
  onSubmit?: () => void;
  area?: boolean;
};

const Label = ({
  value,
}: {
  value?: string;
}) => {
  return <Text bold fontSize={FontSize.Small} className={styles.label}>{value}</Text>
}

export const TextInput = ({
  className,
  setValue,
  onChange,
  onSubmit,
  area,
  value,
  placeholder,
  ...props
}: TextInputProps) => {
  const [ state, setState ] = useState<{
    cursor: [ number | null, number | null ];
    value?: any;
  }>(() => ({
    value,
    cursor: [ 0, 0 ],
  }));
  const ref = useRef(null);

  useEffect(() => {
    setState(state => ({
      ...state,
      value,
    }));
  }, [ value ]);
  useEffect(() => {
    if (setValue) setValue(state.value);
  }, [ state.value ]);

  const _onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);

    setState(() => ({
      value: e.target.value,
      cursor: [ e.target.selectionStart, e.target.selectionEnd ],
    }));
  }, [ setValue ]);

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    // if (area) return false;
    if (!onSubmit) return false;

    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      onSubmit();
    }
  }, [ area, onSubmit ]);

  useEffect(() => {
    if (!ref.current) return;
    // (ref.current as any).setSelectionRange(state.cursor[0], state.cursor[1]);
  }, [ ref, state ]);

  const Tag = useMapping(() => {
    if (area) return (props: any) => <textarea ref={ref} {...props} />
    return (props: any) => <input ref={ref} {...props} />
  }, [ area ]);

  return <div className={styles.textInput}>
      <Label value={placeholder} />
    <Tag
      value={state.value}
      {...props}
      className={clsx(
        className,
        area && styles.area,
      )}
      onKeyDown={onKeyDown}
      onChange={_onChange}
    />
  </div>
}