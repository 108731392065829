import { useMapping } from "../../hooks";
import { Text, FontSize } from "../../ui";

const LineElem = ({
  value,
}: {
  value: string;
}) => {
  return useMapping(() => {
    if (value.trim().startsWith('- ')) {
      const text = value.trim().replace('- ', '');
      return <Text style={{ display: 'list-item', listStyleType: 'square', marginLeft: '1em' }} fontSize={FontSize.Medium}>{text}</Text>
    }

    if (value.trim() === '') {
      return <div style={{ margin: '.5rem' }} />
    }

    return <Text fontSize={FontSize.Medium} style={{ whiteSpace: 'pre-line' }}>{value}</Text>
  }, [ value ]);
}

export const SummaryPanel = ({
  value,
}: {
  value: string;
}) => {
  return useMapping(() => {
    const lines = value.split('\n').map(line => {
      return <LineElem value={line} />
    });

    return <div>
      {lines}
    </div>
  }, [ value ]);
}