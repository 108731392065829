import clsx from 'clsx';
import styles from './card.module.scss';

export type CardProps = {
  size? : 'small' | 'medium' | 'large';
  shape?: 'tall' | 'square';
} & React.HTMLAttributes<HTMLElement>;
export const Card = ({
  className,
  size='medium',
  shape='tall',
  ...props
}: CardProps) => {
  return <div
    className={clsx(
      className,
      styles.card,
      styles[size],
      styles[`${size}-${shape}`],
      props.onClick?.apply && styles.clickable,
    )}
    {...props}
  />;
}