import { useEffect, useState } from "react";
import { useErrors, useMapping, useQueryParam } from "../../hooks"
import { useResumes } from "../../io/resumes-api"
import { Loading } from "../../ui/control/loading";
import { Page } from "../../ui/interactive/page";
import { Panel } from "../../ui/layout/Panel";
import { ResumeGrid } from "../resumes/resume-grid";
import { TextInput } from "../../ui/input/TextInput";
import { Button } from "../../ui/interactive/button";
import { Icon } from "../../ui/imagery/Icon";
import { useNavigate } from "react-router-dom";

export const SearchPage = () => {
  const {
    value: query,
  } = useQueryParam('q', x => x || undefined);
  const nav = useNavigate();
  const [ search, setSearch ] = useState(query);
  const onSearch = () => nav(`/search?q=${encodeURIComponent(search || '')}`);

  useEffect(() => {
    if (search === query) return;
    onSearch();
  }, [ search ]);

  return <Page>
    <Panel>
      <div style={{ margin: '1rem', display: 'flex' }}>
        <div style={{ width: '20rem', margin: 'auto' }}>
          <TextInput placeholder='Search Resumes' value={search} setValue={setSearch} />
        </div>
      </div>
    </Panel>
    <Panel>
      <div style={{ margin: '1rem' }}>
        <ResumeGrid linkTo={x => `/${x.slug}`} query={{ contactName: `like ${search}` }} />
      </div>
    </Panel>
  </Page>
}