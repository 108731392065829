import { ReactNode, createContext } from "react";

type ErrorListener = (err: Error) => JSX.Element | undefined;
class ErrorEmitter {
  listeners: ErrorListener[] = [];

  listen = (listener: ErrorListener) => {
    this.listeners.push(listener);
  }

  onError = (error: Error): JSX.Element => {
    for (const listener of this.listeners) {
      const node = listener(error);
      if (node !== undefined) return node;
    }

    return <></>;
  }
}

const emitter = new ErrorEmitter();

export const useErrors = () => {
  return emitter;
};