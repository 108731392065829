import { useEffect, useState } from "react";
import { useAsync, useMapping } from "../../hooks";
import { Resume, ResumePatch, useResumesApi } from "../../io/resumes-api";
import { TextInput } from "../../ui/input/TextInput";
import { SaveButton } from "../buttons/save-button";
import { TitlePanel } from "./title-panel";

export const EditResumeInfoPanel = ({
  resume,
  setResume,
}: {
  resume: Resume;
  setResume: (x: Resume) => void;
}) => {
  const [ patch, setPatch ] = useState<Partial<ResumePatch>>({});
  const resumesApi = useResumesApi();
  const update = useAsync(async () => {
    const updated = await resumesApi.update(resume.id, patch);
    setResume(updated);
    setPatch(updated);
    return true;
  }, [ patch ], {
    defer: true,
    default: null,
  });

  useEffect(() => {
    setPatch(resume);
  }, [ resume ]);

  const hasChanges = useMapping(() => {
    for (const key of Object.keys(patch)) {
      if ((patch as any)[key] !== (resume as any)[key]) return true;
    }

    return false;
  }, [ resume, patch ]);
  
  return <TitlePanel title={"Info"}>
            <TextInput disabled={update.loading} placeholder={"Contact Name"} maxLength={32}
              value={patch.contactName}
              setValue={contactName => setPatch({ ...patch, contactName })}
            />
            <TextInput disabled={update.loading} placeholder={"Email"} maxLength={64}
              value={patch.email || undefined}
              setValue={email => setPatch({ ...patch, email })}
            />
            <TextInput disabled={update.loading} placeholder={"LinkedIn Url"} maxLength={64}
              value={patch.linkedInUrl || undefined}
              setValue={linkedInUrl => setPatch({ ...patch, linkedInUrl })}
            />
            <TextInput disabled={update.loading} placeholder={"Personal Url"} maxLength={64}
              value={patch.personalUrl || undefined}
              setValue={personalUrl => setPatch({ ...patch, personalUrl })}
            />
    <TextInput disabled={update.loading} area style={{ resize: 'none', height: '10rem' }}
      placeholder={"About"}
      value={patch.about || undefined}
      setValue={about => setPatch({ ...patch, about })}
    />
    <TextInput disabled={update.loading} area style={{ resize: 'none', height: '10rem' }}
      placeholder={"Summary"}
      value={patch.summary || undefined}
      setValue={summary => setPatch({ ...patch, summary })}
    />
    <TextInput disabled={update.loading} area style={{ resize: 'none', height: '10rem' }}
      placeholder={"Work Experience"}
      value={patch.workExperience || undefined}
      setValue={workExperience => setPatch({ ...patch, workExperience })}
    />
            <SaveButton disabled={!hasChanges || update.loading} task={update} />
  </TitlePanel>
}