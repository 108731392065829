import React from 'react';

import styles from './Text.module.scss';
import clsx from 'clsx';

export enum FontSize {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type TextProps = {
  fontSize?: FontSize;
  center?: boolean;
  bold?: boolean;
  capitalize?: boolean;
  paragraph?: boolean;
  error?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export const Text = ({
  fontSize=FontSize.Medium,
  center,
  capitalize,
  className,
  bold,
  paragraph,
  error,
  ...props
}: TextProps) => {
  return <div
    {...props}
    className={clsx(
      className,
      styles.text,
      fontSize && styles[fontSize],
      center && styles.center,
      capitalize && styles.capitalize,
      bold && styles.bold,
      error && styles.error,
    )}
  />;
}