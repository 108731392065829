import 'react-circular-progressbar/dist/styles.css'
import { useParams } from 'react-router-dom'
import { useAsync, useErrors, useHttp, useMapping, useQueryParam } from '../../hooks'
import { useFindResume } from '../../io/resumes-api'
import { Grid } from "../../ui"
import { Loading } from '../../ui/control/loading'
import { Page } from "../../ui/interactive/page"
import { ResumeColumnA } from "../resumes/resume-column-a"
import {Helmet} from "react-helmet";

export const ResumePage = () => {
  const {
    slug,
  } = useParams();
  const http = useHttp();
  const errors = useErrors();
  const resume = useFindResume({
    slug,
  });

  const view = useMapping(() => {
    if (resume.loading) return <Loading />
    if (resume.error) return errors.onError(resume.error);

    return <Grid center>
      <ResumeColumnA resume={resume.value} />
    </Grid>
  }, [ resume.loading, resume.error, resume.value ]);

  return <Page>
    <Helmet>
      <title>Uptime Resume</title>
      <meta property="og:title" content="Uptime Resume" />
      <meta property="og:description" content={`${resume.value?.contactName}'s Resume`} />
      <meta property="og:image" content={`/api/v1/resumes/${resume.value?.id}/image`} />
    </Helmet>
    <Grid center>
      <div style={{ maxWidth: '40rem', width: '40rem' }}>
        {view}
      </div>
    </Grid>
  </Page>
}