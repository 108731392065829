import { AxiosInstance } from "axios";
import { Emitter } from "event-emitter";
import { useEffect } from "react";
import { useAsync, useHttp, useMapping } from "../hooks";
import useEvents from "../hooks/use-events";
import { AppError } from "./errors";

export type Skill = {
  industryTag: string;
  tag: string;
}

export type SkillQueryParams = {
  industryTag?: string;
  tag?: string;
  limit?: number;
}

export class SkillsApi {
  constructor (
    private client: AxiosInstance,
    private events: Emitter,
  ) {
    this.client.interceptors.response.use(null, err => {
      if (!err.response) return Promise.reject(err);

      if (!err.response.headers['content-type'].endsWith('/json')) return Promise.reject(err);
      const data = err.response.data;
      if ('code' in data) {
        return Promise.reject(new AppError(data.code, data.issues));
      }

      return Promise.reject(err);
    });
  }

  create = async (input: Skill): Promise<Skill> => {
    return await this.client.post('/api/v1/skills', input)
      .then(r => r.data)
      .then(industry => {
        this.events.emit('skill.created', industry);
        return industry;
      });
  }

  find = async (query: SkillQueryParams={}) => {
    return await this.client.get(`/api/v1/skills`, {
      params: query,
    })
      .then(r => r.data as Skill[]);
  }
}

export const useSkillsApi = () => {
  const http = useHttp();
  const events = useEvents();

  return useMapping(() => {
    return new SkillsApi(http.client, events);
  }, [ http.client ]);
}

export const useSkills = (query: SkillQueryParams={}) => {
  const industrysApi = useSkillsApi();
  const events = useEvents();
  const task = useAsync(() => industrysApi.find(query), [ ...Object.values(query) ]);

  useEffect(() => {
    const f = () => task.trigger(true);
    events.on('skill.created', f);
    return () => events.off('skill.created', f);
  }, [ industrysApi ]);

  return task;
}