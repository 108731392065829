import { AxiosInstance } from "axios";

var dataURLToBlob = function(dataURL) {
  var BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      const raw = parts[1];

      return new Blob([raw], {type: contentType});
  }

  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  var rawLength = raw.length;

  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {type: contentType});
}

export const uploadImage = async (client: AxiosInstance, endpoint: string, file: File, maxSize: number) => {
  return await new Promise((done, fail) => {
    // Load the image
    var reader = new FileReader();
    reader.onload = function (readerEvent) {
      var image = new Image() as any;
      image.onload = function (imageEvent) {
        // Resize the image
        var canvas = document.createElement('canvas'),
          max_size = maxSize,
          width = image.width,
          height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        //ctx.drawImage(image, 0, 0, width, height);
        // ctx.filter = `blur(1px)`;
        ctx.drawImage(canvas, 0, 0);
        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        var resizedImage = dataURLToBlob(dataUrl);
        const form = new FormData();
        form.append(file.name, resizedImage);

        return client({
          method: 'POST',
          url: endpoint,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: form,
        }).then(r => r.data)
          .then(done)
          .catch(fail);
      }
      image.src = readerEvent?.target?.result;
    }
    reader.readAsDataURL(file);
  });
}