import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../hooks/use-auth";
import useAuth0, { Auth0Provider } from "../../hooks/use-auth0";
import { Page } from "../../ui/interactive/page";
import { Loading } from "../../ui/control/loading";
import { useErrors, useMapping } from "../../hooks";
import { Grid } from "../../ui/layout/Grid";

export const LoginView = () => {
  const lock = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const history = useNavigate();
  const auth = useAuth();
  const errors = useErrors();

  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState<any | null>(null);
  const [ showing, setShowing ] = useState(() => false);

  React.useEffect(() => {
    if (auth.user) {
      navigate('/');
      return;
    }
    if (showing) return;

    lock.checkSession({ }, (err, sess) => {
      console.log('Checking session');
      if (err) {
        if (err.code === 'login_required') {
          setShowing(true);
          lock.show();
          return
        }
        console.log(err);
        setError(err);
        return;
      }

      if (sess === undefined) {
        lock.show();
        setShowing(true);
        return;
      }
      auth.setToken(sess.accessToken);
      navigate('/');
    });

    lock.on('show', () => setLoading(false));
    lock.on('hide', () => navigate(-1));

    lock.on('authenticated', (res) => {
      console.log('Lock authenticated');
      auth.setToken(res.accessToken);
      lock.hide();
      history("/", { replace: true });
    });

    lock.on('hide', (res: any) => {
      console.log('hidden', res);
    })

    lock.on('authorization_error', err => {
      console.log('error', err);
      auth.logout();
      setError(err);
    })

    lock.on('unrecoverable_error', console.log);
  }, [ auth.user, location.pathname, showing ]);

  return useMapping(() => {
    if (loading) return <Loading />
    if (error) return errors.onError(error);

    return <></>
  }, [ loading, error ]);
}

export const LoginPage = () => {
  return <Auth0Provider>
    <Page key='login-page'>
      <Grid center>
        <LoginView />
      </Grid>
    </Page>
  </Auth0Provider>
}

export default LoginPage;