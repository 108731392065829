import { useEffect, useRef } from 'react';
import styles from './modals.module.scss';
import clsx from 'clsx';

export type ModalProps = {
  children: JSX.Element[] | JSX.Element;
  enabled?: boolean;
  onClose?: () => void;
}

export const Modal = ({
  children,
  enabled,
  onClose,
}: ModalProps) => {
  useEffect(() => {
    if (!enabled) return;
    const scrollY = window.scrollY;
    window.scrollTo({ top: 0 });

    const overflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = overflow;
      window.scrollTo({ top: scrollY });
    }
  }, [ enabled ]);

  return <>
    <div className={clsx(enabled ? null : styles.hidden, styles.modalView)} onClick={() => onClose && onClose()}>
      <div className={styles.modalContent}>
        <div className={styles.modal} onClick={e => { e.stopPropagation() }}>
          {children}
        </div>
      </div>
    </div>
  </>
}