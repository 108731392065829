import React, { useCallback, useEffect, useState } from 'react';
import { Loading } from '../ui/control/loading';
import { useAsync } from './use-async';
import { useErrors } from './use-errors';
import useHttp from './use-http';
import { useMapping } from './use-mapping';
import { redirect, useNavigate } from 'react-router-dom';
import useEvents from './use-events';

export type UserInfo = {
  userId: string;
  roles?: string[];
}

export type AuthState = {
  user: UserInfo | null;
  setToken: (x: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthState>({
  user: null,
  setToken: async (x:string) => undefined,
  logout: async () => undefined,
});

export type AuthContextProps = {
  children: React.ReactNode;
}

export const LocalAuthProvider = ({ children }: AuthContextProps) => {
  const [ user, setUser ] = useState<UserInfo>({
    userId: "tester",
    roles: [],
  });

  return <AuthContext.Provider value={{
    user,
    setToken: async () => {},
    logout: async () => {},
  }}>
    {children}
  </AuthContext.Provider>
}

export const AuthProvider = ({ children }: AuthContextProps) => {
  const http = useHttp();
  const events = useEvents();
  const nav = useNavigate();

  const user = useAsync<UserInfo | null>(async () => {
    return await http.client.get('/api/auth/session')
      .then(r => r.data)
      .catch(e => {
        console.log(e);
        return null;
      })
  }, [ ], {
    debounce: true,
  });

  useEffect(() => {
    const onFocus = (e: FocusEvent) => {
      if (e.type === 'focus') {
        user.trigger();
      }
    }
    
    // const timeout = setTimeout(() => {
    //   user.trigger();
    // }, 60000);

    // return () => clearTimeout(timeout);
  }, [ user.trigger ]);

  const setToken = useCallback(async (token: string) => {
    console.log('Setting token...')
    await http.client.post('/api/auth/login', {
      token,
    });
    console.log('triggering user reload');

    user.trigger(true);
  }, [ user.trigger ]);

  const logout = React.useCallback(async () => {
    console.log('Emitting logout');
    await http.client.post('/api/auth/logout');
    nav('/logout');
    //events.emit('logout', user);
  }, [ user ]);

  const errors = useErrors();

  // useEffect(() => {
  //   if (!activeWindow) return;
  //   console.log('Window active');

  //   if (redirect) {
  //     console.log('Window redirect');
  //     setRedirect(null);
  //     setUser(null);
  //     navigate(redirect, { replace: true });
  //     return;
  //   }

  //   http.client.get('/api/auth/session')
  //     .then(r => r.data)
  //     .then(setUser)
  //     .catch(err => {
  //       if (err.response?.status === 401) {
  //         setRedirect(location.pathname);
  //         logout();
  //         navigate('/');
  //       }
  //       console.log(err);
  //     })
  // }, [ activeWindow ]);

  return useMapping(() => {
    console.log('Loading auth', user)
    if (user.loading) return <Loading />
    if (user.error) return errors.onError(user.error);

    return <AuthContext.Provider value={{
      user: user.value,
      setToken,
      logout,
    }}>
      {children}
    </AuthContext.Provider>
  }, [ user.loading, user.value ])
}

export const useAuth = () => React.useContext(AuthContext);
export default useAuth;
