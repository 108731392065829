import { useState } from "react";
import Loading from "react-loading";
import { useAsync, useErrors, useMapping, useQueryParam } from "../../hooks";
import { Industry, useIndustriesApi } from "../../io/industries-api";
import { useSkills, useSkillsApi } from "../../io/skills-api";
import { Grid, Text } from "../../ui";
import { Icon } from "../../ui/imagery/Icon";
import { TextInput } from "../../ui/input/TextInput";
import { Button } from "../../ui/interactive/button";
import { Hotbar } from "../../ui/interactive/hotbar";
import { Panel } from "../../ui/layout/Panel";
import { HorizontalBreak } from "../../ui/layout/horizontal-break";
import { IndustriesList } from "./industries-list";
import { IndustriesLoader } from "./industries-loader";
import { Modal } from "../../ui/modals/modal";

const SkillsView = ({
  industryTag,
  onBack,
}: {
  industryTag: string;
  onBack: () => void;
}) => {
  const errors = useErrors();
  const skills = useSkills({
    industryTag,
  });
  const [ showAdd, setShowAdd ] = useState(false);
  const [ addInput, setAddInput ] = useState("");

  const skillsApi = useSkillsApi();
  const create = useAsync(async () => {
    const value = await skillsApi.create({
      industryTag,
      tag: addInput,
    });
    setShowAdd(false);
    setAddInput("");
    return value;
  }, [ addInput, industryTag ], {
    defer: true,
    default: null,
  });

  const listView = useMapping(() => {
    if (skills.loading) return <Loading />
    if (skills.error) return errors.onError(skills.error);

    const items = skills.value.map(skill => {
      return <li>
        <Panel>
          <div style={{ margin: '1rem' }}>
            <Text capitalize>{skill.tag}</Text>
          </div>
        </Panel>
      </li>
    });

    return <ul style={{ margin: 'auto', maxWidth: '30rem' }}>
      {items}
    </ul>
  }, [ skills.loading, skills.error, skills.value ]);

  return <>
    <Hotbar>
      <Button style={{ display: 'flex', alignItems: 'center' }} onClick={onBack} >
        <Icon style={{ margin: 'auto' }} icon="chevron_left" />
        <Text>Back</Text>
      </Button>
      <Button style={{ display: 'flex', alignItems: 'center' }} onClick={() => setShowAdd(true)} >
        <Icon style={{ margin: 'auto' }} icon="add" />
        <Text>New</Text>
      </Button>
    </Hotbar>
    <div style={{ margin: '1rem' }}>
      <Text capitalize center>Skills</Text>
      <div style={{ marginTop: '1rem' }} />
      <HorizontalBreak />
      <div style={{ marginTop: '1rem' }} />
      {listView}
    </div>
    <Modal enabled={showAdd} onClose={() => setShowAdd(false)}>
      <Panel>
        <div style={{ margin: '1rem', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <TextInput placeholder="Tag" value={addInput} setValue={setAddInput} />
          <Grid center>
            <Button onClick={() => create.trigger()}>Create</Button>
          </Grid>
        </div>
      </Panel>
    </Modal>
  </>
}

export const IndustriesView = ({
  onSelected,
}: {
  onSelected: (x: Industry) => void;
}) => {
  const industriesApi = useIndustriesApi();
  const [ tagInput, setTagInput ] = useState<string>('');
  const [ createOpen, openCreateModal ] = useState(false);
  const create = useAsync(async () => {
    const res = await industriesApi.create({ tag: tagInput });
    openCreateModal(false);
    setTagInput('');
    return res;
  }, [ ]);

  return <>
    <Hotbar>
      <Button style={{ display: 'flex', alignItems: 'center' }} onClick={() => openCreateModal(true)} >
        <Icon style={{ margin: 'auto' }} icon="add" />
        <Text>New</Text>
      </Button>
    </Hotbar>
    <div style={{ margin: '1rem' }}>
      <Text capitalize center>Industries</Text>
      <div style={{ marginTop: '1rem' }} />
      <HorizontalBreak />
      <div style={{ marginTop: '1rem' }} />
      <IndustriesLoader
        query={{}}
        Component={IndustriesList}
        onSelected={onSelected}
      />
      <Modal enabled={createOpen} onClose={() => openCreateModal(false)}>
        <Panel>
          <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextInput placeholder="Industry Name" value={tagInput} setValue={setTagInput} />
            <Grid center>
              <Button onClick={() => create.trigger()}>Create</Button>
            </Grid>
          </div>
        </Panel>
      </Modal>
    </div>
  </>
}

export const IndustriesAdminSection = () => {
  const {
    value: industry,
    setParam,
  } = useQueryParam('industry', x => x);

  const setIndustry = (x: Industry) => setParam(x.tag);

  const view = useMapping(() => {
    if (industry === null) {
      return <IndustriesView onSelected={setIndustry} />
    }

    return <SkillsView industryTag={industry} onBack={() => setParam(null)}/>

  }, [ industry ]);

  return <Panel>
    {view}
  </Panel>
}