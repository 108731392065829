import { useMapping } from "../../hooks";
import { Card, Image } from "../../ui";

const FilePreview = ({
  file,
}: {
  file: File;
}) => {
  return useMapping(() => {
    if (file.type.startsWith('image/')) {
      return <Image fullHeight objectFit='contain' src={URL.createObjectURL(file)} />
    }

    return <></>
  }, [ file ]);
}

export const FileCard = ({
  file,
}: {
  file: File;
}) => {
  return <>
    <FilePreview file={file} />
  </>
}