import React from 'react';

import clsx from 'clsx';
import styles from './button.module.scss';

export type ButtonProps = {
  round?: boolean;
  flat?: boolean;
  cover?: boolean;
  active?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({ 
  className,
  round=false,
  flat=false,
  cover=false,
  active=false,
  ...props
}: ButtonProps) => {
  return <button
    {...props}
    className={clsx(
      className,
      styles.button,
      round && styles.round,
      flat && styles.flat,
      cover && styles.cover,
      active && styles.active,
    )}
  />
}