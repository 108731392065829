import { Link } from "react-router-dom";
import { useErrors, useMapping } from "../../hooks";
import { Resume, ResumeQueryParams, useResumes, useResumesApi } from "../../io/resumes-api"
import { Loading } from "../../ui/control/loading";
import { Card, Grid, Text, Image, FontSize } from "../../ui";
import { HorizontalBreak } from "../../ui/layout/horizontal-break";
import { ResumeImage } from "./resume-image";
import { Panel } from "../../ui/layout/Panel";

export const ResumeGrid = ({
  query,
  linkTo,
}: {
  query: ResumeQueryParams;
  linkTo: (resume: Resume) => string;
}) => {
  const resumes = useResumes(query);
  const loadTime = useMapping(() => new Date(), [ resumes.loading ]);
  const errors = useErrors();

  const items = useMapping(() => {
    if (resumes.error) return errors.onError(resumes.error);
    if (resumes.loading) {
      if (new Date().getSeconds() - loadTime.getSeconds() < 1000) {
        return <></>
      }
      return <Loading />
    }
    return resumes.value.map(resume => {
      return <Link key={`resume-grid-link-${resume.id}`} to={linkTo(resume)}>
        <Card key={`resume-grid-card-${resume.id}`}>
          <div style={{ width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ margin: 'auto', width: '10rem', height: '10rem', borderRadius: '50%', overflow: 'hidden', boxShadow: '0px 0 4px 2px grey' }}>
              <Image style={{ width: '100%', height: '100%' }} objectFit='cover' src={`/api/v1/resumes/${resume.id}/image`} />
            </div>
          </div>
          <div style={{ height:'20%', display: 'flex' }}>
            <Text style={{ margin: 'auto' }} center capitalize fontSize={FontSize.Medium}>
              {resume.contactName}
            </Text>
          </div>
        </Card>
      </Link>
    });
  }, [ resumes.loading, resumes.value, resumes.error ]);

  return <Grid key={'resumes-grid'}>
    {items}
  </Grid>
}