import { useState } from "react";
import { useAsync, useHttp, useMapping } from "../../hooks"
import { Card, Text, FontSize, Grid } from "../../ui";
import { Panel } from "../../ui/layout/Panel";
import { VerifiedReference } from "../../io/references-api";
import { Button } from "../../ui/interactive/button";
import { Icon } from "../../ui/imagery/Icon";
import { DocumentStatus, useDocuments } from "../../io/documents-api";

export const VerifiedReferencesSection = ({
  resumeId,
  onSelect,
  selected,
  search,
}: {
  resumeId: string;
  onSelect: (x: VerifiedReference) => void;
  selected: string | null;
  search: string;
}) => {
  const http = useHttp();
  const references = useAsync(async () => {
    return await http.client.get('/api/v1/verified-references', {
      params: {
        resumeId,
      }
    })
    .then(r => r.data as VerifiedReference[])
  }, [ resumeId ]);

  const documents = useDocuments({
    resumeId,
    statusIn: [
      DocumentStatus.PendingReview,
      DocumentStatus.Approved,
    ],
  });

  const referenceViews = useMapping(() => {
    if (documents.loading) return <></>
    if (documents.error) return <></>
    if (references.loading) return <></>
    if (references.error) return <></>

    const refs = (() => {
      if (!search) return references.value;

      return references.value.filter(ref => {
        const refDocs = documents.value.filter(x => x.referenceId === ref.referenceId);
        return ref.contactName.toLowerCase().includes(search)
          || refDocs.find(doc => {
            return doc.description.toLowerCase().includes(search);
          });
      });
    })();

    return refs.map(ref => {
      const refDocs = documents.value.filter(x => x.referenceId === ref.referenceId);
      //return <Link to={`?reference=${ref.referenceId}&q=${search}`}>
      return <>
        <Button active={ref.referenceId === selected} style={{ margin: '1rem' }} onClick={() => onSelect(ref)}>
          <div style={{ textAlign: 'center' }}>
            <Text center capitalize>{ref.contactName}</Text>
            <Text center fontSize={FontSize.Small}>Work Verified {refDocs.length || 0}</Text>
          </div>
        </Button>
      </>
    });
  }, [ search, selected, references.loading, references.error, references.value, documents.value, documents.loading ]);

  const heading = useMapping(() => {
    if (search) return <Text center>(Search Results)</Text>

    if (!references.value) return <> </>

    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Text center fontSize={FontSize.Large} bold>
        <span style={{ color: 'blue' }}>
          Work References
        </span>
        ({references.value?.length})
      </Text>
    </div>
  }, [ references.value, search ]);

  return <Panel>
    <div style={{ margin: '1rem' }}>
      <div>
        {heading}
      </div>
      <Grid center style={{ gap: '.1rem' }}>
        {referenceViews}
      </Grid>
    </div>
  </Panel>
}