import { AxiosInstance } from "axios";
import { AsyncArgs, useAsync, useHttp, useMapping } from "../hooks";
import { useEffect } from "react";
import useEvents from "../hooks/use-events";
import { Emitter } from "event-emitter";
import { AppError, ErrorCode } from "./errors";
import { uploadImage } from "./files-api";

export enum DocumentStatus {
  Idle = 'idle',
  PendingReview = 'pending-review',
  Approved = 'approved',
  Declined = 'declined',
  Error = 'error',
}

export type Document = {
  id: string;
  resumeId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  description: string;
  fileId: string | null;
  referenceId: string | null;
  industryTag: string | null;
  skills: string[];
  status: DocumentStatus;
  error: AppError | null;
}

export type DocumentInput = {
  resumeId: string;
  referenceId?: string;
}

export type DocumentPatch = {
  description?: string | null;
  fileId?: string | null;
  referenceId?: string | null;
  industryTag?: string | null;
  skills?: string[];
}

export type DocumentQueryParams = {
  resumeId?: string;
  referenceId?: string;
  status?: DocumentStatus;
  statusIn?: DocumentStatus[];
  limit?: number;
}

export class DocumentsApi {
  constructor (
    private client: AxiosInstance,
    private events: Emitter,
  ) {
    this.client.interceptors.response.use(null, err => {
      if (!err.response) return Promise.reject(err);

      if (!err.response.headers['content-type'].endsWith('/json')) return Promise.reject(err);
      const data = err.response.data;
      if ('code' in data) {
        return Promise.reject(new AppError(data.code, data.issues));
      }

      return Promise.reject(err);
    });
  }

  create = async (input: DocumentInput): Promise<Document> => {
    return await this.client.post('/api/v1/documents', input)
      .then(r => r.data)
      .then(document => {
        this.events.emit('document.created', document);
        return document;
      });
  }

  update = async (documentId: string, patch: DocumentPatch): Promise<Document> => {
    return await this.client.patch(`/api/v1/documents/${documentId}`, patch)
      .then(r => r.data)
      .then(document => {
        this.events.emit('document.updated', document);
        return document;
      });
  }

  find = async (query: DocumentQueryParams={}) => {
    return await this.client.get(`/api/v1/documents`, {
      params: query,
    })
      .then(r => r.data as Document[]);
  }

  get = async (documentId: string) => {
    return await this.client.get(`/api/v1/documents/${documentId}`)
      .then(r => r.data as Document);
  }

  findOne = async (query: DocumentQueryParams={}): Promise<Document> => {
    const rows = await this.find({
      ...query,
      limit: 1,
    });
    if (!rows || rows.length === 0) throw new AppError(
      ErrorCode.NotFound,
      [],
    );

    return rows[0];
  }

  delete = async (documentId: string): Promise<Document> => {
    return await this.client.delete(`/api/v1/documents/${documentId}`)
      .then(r => r.data as Document)
      .then(document => {
        this.events.emit('document.deleted', document);
        return document;
      });
  }

  submit = async (documentId: string): Promise<Document> => {
    return await this.client.post(`/api/v1/documents/${documentId}/submit`)
      .then(r => r.data as Document)
      .then(document => {
        this.events.emit('document.updated', document);
        return document;
      });
  }

  decline = async (documentId: string): Promise<Document> => {
    return await this.client.post(`/api/v1/documents/${documentId}/decline`)
      .then(r => r.data as Document)
      .then(document => {
        this.events.emit('document.updated', document);
        return document;
      });
  }

  approve = async (documentId: string): Promise<Document> => {
    return await this.client.post(`/api/v1/documents/${documentId}/approve`)
      .then(r => r.data as Document)
      .then(document => {
        this.events.emit('document.updated', document);
        return document;
      });
  }

  uploadFile = async (documentId: string, file: File) => {
    await uploadImage(
      this.client,
      `/api/v1/documents/${documentId}/thumbnail`,
      file,
      1024,
    );
    await uploadImage(
      this.client,
      `/api/v1/documents/${documentId}/preview`,
      file,
      2048,
    );
    await uploadImage(
      this.client,
      `/api/v1/documents/${documentId}/file`,
      file,
      2048,
    );

    // const form = new FormData();
    // form.append(file.name, file);

    // return await this.client({
    //   method: 'POST',
    //   url: `/api/v1/documents/${documentId}/file`,
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    //   data: form,
    // }).then(r => r.data as Document);
  }
}

export const useDocumentsApi = () => {
  const http = useHttp();
  const events = useEvents();

  return useMapping(() => {
    return new DocumentsApi(http.client, events);
  }, [ http.client ]);
}

export const useDocuments = (query: DocumentQueryParams={}) => {
  const documentsApi = useDocumentsApi();
  const events = useEvents();
  console.log(query);
  const task = useAsync(() => documentsApi.find(query), [ JSON.stringify(query) ]);

  useEffect(() => {
    const f = () => task.trigger(true);
    const onUpdate = x => {
      if (!task.value) return;
      if (!task.value.find(y => y.id === x.id)) return;

      task.setValue(
        task.value.map(y => {
          if (x.id === y.id) return x;
          return y;
        })
      );
    }

    events.on('document.updated',onUpdate);
    events.on('document.created', f);
    events.on('document.deleted', f);
    return () => {
      events.off('document.updated', onUpdate);
      events.off('document.created', f);
      events.off('document.deleted', f);
    }
  }, [ documentsApi, task.loading, task.value ]);

  return task;
}

export const useDocument = (documentId: string) => {
  const documentsApi = useDocumentsApi();
  const events = useEvents();
  const task = useAsync(async () => {
    return await documentsApi.get(documentId);
  }, [ documentId ]);

  useEffect(() => {
    const f = (document: Document) => {
      if (document.id !== documentId) return;
      task.setValue(document);
    };
    events.on('document.updated', f);
    return () => events.off('document.updated', f);
  }, [ documentId, documentsApi ]);

  return task;
}