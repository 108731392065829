import { Auth0Lock, Auth0LockPasswordless } from 'auth0-lock';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router';
import { useAsync } from './use-async';
import useAuth from "./use-auth";
import { useMapping } from './use-mapping';
import { Loading } from '../ui/control/loading';
import { Text } from '../ui';
import { useErrors } from './use-errors';
import useEvents from './use-events';
import useHttp from './use-http';

type AuthConfig = {
  clientId: string;
  domain: string;
  audience?: string;
}

export const Auth0Context = React.createContext<typeof Auth0LockPasswordless>(null as any);

export type Auth0ContextProps = {
  children: JSX.Element | JSX.Element[];
};

export const loadAuth0 = async () => {
  return await axios.get('/api/auth/config')
    .then(r => r.data as AuthConfig)
    .then(config => new Auth0Lock( 
      config.clientId,
      config.domain,
      {
        auth: {
          redirect: false,
          sso: true,
          params: {
            scope: "openid profile email id_token",
          },
          audience: config.audience,
        },
      },
    ));
}

export const Auth0Provider = ({ children }: Auth0ContextProps) => {
  const location = useLocation();
  // const [ lock, setLock ] = React.useState<typeof Auth0LockPasswordless | undefined>();
  const [ error, setError ] = useState<Error | null>(null);
  const http = useHttp();
  const auth = useAuth();
  const events = useEvents();

  useEffect(() => {
    console.log("Loading auth0 provider");
  }, []);

  const lock = useAsync(async () => {
    return await axios.get('/api/auth/config')
      .then(r => r.data as AuthConfig)
      .then(config => new Auth0Lock( 
        config.clientId,
        config.domain,
        {
          auth: {
            redirect: false,
            sso: true,
            params: {
              scope: "openid profile email id_token http://uptime.com/roles http://uptime.com/permissions",
            },
            audience: config.audience,
          },
        },
      ))
      .then(lock => {
        // lock.on('authenticated', res => {
        //   console.log('authenticated');
        //   console.log(res.accessToken);
        //   auth.setToken(res.accessToken);
        //   lock.hide();
        // });
        lock.on('authenticated', res => {
          console.log('auth0', res);
        });

        lock.on('authorization_error', err => {
          console.log('Auth error', err);
          setError(new Error('Sorry! An authorization error occurred.'));
          http.client.post('/api/errors', {
            error: err,
          });
        });
        lock.on('unrecoverable_error', err => {
          console.log('Auth error', err);
          setError(new Error('Sorry! An unrecoverable error occurred.'));
          http.client.post('/api/errors', {
            error: err,
          });
        });

        lock.on('hide', () => {
          console.log('hidden');
        });

        return lock;
      });
  }, []);

  useEffect(() => {
    const onLogout = () => {
      console.log('Got event logout');
      if (lock.loading) {
        return null;
      }
      if (lock.error) return null;

      lock.value.logout({
        returnTo: window.origin + '/',
      });
      // window.location.pathname = '/api/auth/logout';
    };

    console.log('listening for logout events');
    events.on('logout', onLogout);
    return () => events.off('logout', onLogout);
  }, [ events, lock.loading ]);

  useEffect(() => {
    if (auth.user) lock.value?.hide();
  }, [ auth.user ]);

  const errors = useErrors();

  return useMapping(() => {
    if (error) return errors.onError(error);
    if (lock.loading) return <Loading />;
    if (lock.error) return errors.onError(lock.error);

    return <Auth0Context.Provider value={lock.value}>
      {children}
    </Auth0Context.Provider>
  }, [ lock.loading, error ]);
}

const useAuth0 = () => React.useContext(Auth0Context);
export default useAuth0;