import { Link } from "react-router-dom";
import { useMapping } from "../../hooks";
import { Resume } from "../../io/resumes-api";
import { Card, Grid } from "../../ui";
import { Page } from "../../ui/interactive/page";
import { HorizontalBreak } from "../../ui/layout/horizontal-break";
import { ResumeGrid } from "../resumes/resume-grid";
import { Button } from "../../ui/interactive/button";
import { Text } from "../../ui";

export const LandingPage = () => {
  return <Page>
    <div style={{ padding: '1rem', marginTop: '5rem', width: '100%' }}>
      <Text center>Please login to continue.</Text>
      <Grid center>
        <Link to='/login'>
          <Button>Login</Button>
        </Link>
      </Grid>
    </div>
  </Page>
}