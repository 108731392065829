import clsx from "clsx";
import React from "react";

import styles from './Grid.module.scss';

export type GridProps = {
  center?: boolean;
} & React.HTMLAttributes<HTMLElement>;
export const Grid = ({
  className,
  center,
  ...props
}: GridProps) => {
  return <div
    {...props}
    className={clsx(
      className,
      styles.grid,
      center && styles.center,
    )}
  />;
}