import { createRef, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useAsync, useHttp, useMapping } from "../../hooks";
import { Document, DocumentStatus, useDocuments } from "../../io/documents-api";
import { VerifiedReference } from "../../io/references-api";
import { Resume } from "../../io/resumes-api";
import { FontSize, Image, Text } from "../../ui";
import { Loading } from "../../ui/control/loading";
import { Button } from "../../ui/interactive/button";
import { Panel } from "../../ui/layout/Panel";
import { HorizontalBreak } from "../../ui/layout/horizontal-break";
import { Modal } from "../../ui/modals/modal";
import { ApprovedDocumentPanel } from "../documents/approved-document-panel";
import { VerifiedReferencesSection } from "../references/verified-references-section";
import { ResumeHeader } from "./resume-header";
import styles from './resumes.module.scss';
import { SummaryPanel } from "./summary-panel";

export const SkillsSection = ({
  resumeId,
  referenceId,
  selected,
  onSelect,
  search,
}: {
  referenceId?: string | null;
  resumeId: string;
  selected: string[];
  onSelect: (x: string) => void;
  search?: string;
}) => {
  const documents = useDocuments({
    referenceId: referenceId || undefined,
    resumeId,
    statusIn: [
      DocumentStatus.PendingReview,
      DocumentStatus.Approved,
    ],
  });

  const filterReferences = (): (xs: Document[]) => Document[] => {
    if (!referenceId) return xs => xs;
    return refs => refs.filter(x => x.referenceId === referenceId);
  }

  const items = useMapping(() => {
    if (!documents.value) return;

    let skillValues = 
      filterReferences()(documents.value)
      .map(doc => doc.skills || [])
      .reduce((xss, xs) => [ ...xss, ...xs ], [])

    if (search) skillValues = skillValues.filter(x => x.toLowerCase().includes(search));
    
    const skills = new Set(skillValues);

    return Array.from(skills.values()).map(x => {
      const count = skillValues.filter(y => y === x).length;

      return<Button active={!!selected.includes(x)} onClick={() => onSelect(x)} style={{ margin: '.25rem' }}>
        <Text capitalize fontSize={FontSize.Small}>{x} ({count})</Text>
      </Button>
    });
  }, [ referenceId, selected, documents.loading, search ]);

  return <Panel style={{ minWidth: '20rem', maxWidth: '40rem' }}>
    <div style={{ margin: '1rem' }}>
      <Text capitalize bold fontSize={FontSize.Large}>Skills</Text>
      <div style={{ marginTop: '.5rem' }} />
      <HorizontalBreak />
      <div style={{ marginTop: '.5rem' }} />
      {items}
    </div>
  </Panel>
}

const DocumentThumbnails = ({
  documents,
  onSelected,
}: {
  documents: Document[];
  onSelected: (x: Document) => void;
}): JSX.Element[] => {
  // Chunk by 4 in size.
  const images = documents.map(document => {
    return <div style={{ padding: '5px', width: '45%', height: '45%', boxShadow: '0 0 3px 0 lightgrey' }} onClick={() => onSelected(document)} >
      <Image objectFit='contain' src={`/api/v1/documents/${document.id}/thumbnail`} alt={`/api/v1/documents/${document.id}/preview`} />
    </div>
  });
  if (!images) return [];

  const chunks: JSX.Element[][] = [];
  for (let i = 0; i < Math.ceil(images.length / 4); i++) {
    chunks.push(images.slice(i * 4, (i + 1) * 4));
  }

  return chunks.map(chunk => {
    return <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center', alignItems: 'center' }} className={styles.carouselImage}>
      {chunk}
    </div>
  })
}

const DocumentModal = ({
  document,
  onImageClicked,
}: {
  document: Document;
  onImageClicked: (src: string) => void;
}) => {
  const http = useHttp();
  const reference = useAsync(async () => {
    const rows = await http.client.get('/api/v1/verified-references', {
      params: {
        referenceId: document.referenceId,
      },
    }).then(r => r.data as VerifiedReference[]);

    if (rows.length <= 0) throw new Error('not-found');
    return rows[0];
  }, [ document.referenceId ]);

  return useMapping(() => {
    if (!reference.value) return <Loading />

    return <ApprovedDocumentPanel
      reference={reference.value}
      document={document}
      onImageClicked={onImageClicked}
    />
  }, [ reference.value ]);
}

const DocumentsCarousel = ({
  documents,
}: {
  documents: Document[];
}) => {
  const [ selectedDocument, setSelectedDocument ] = useState<Document | null>(null);
  // State to track if a user has opened the document modal and then clicked on the image to expand it.
  const [ selectedImage, setSelectedImage ] = useState<string | null>(null);

  const onImageClicked = (src: string) => {
    setSelectedImage(src)
  }

  const modalView = useMapping(() => {
    if (!selectedDocument) return <></>
    if (selectedImage) {
      return <div style={{ padding: '1rem' }}>
        <Image src={selectedImage} onClick={() => setSelectedImage(null)} />
      </div>
    }

    return <DocumentModal document={selectedDocument} onImageClicked={onImageClicked} />
  }, [ selectedDocument, selectedImage ]);

  const images = useMapping(() => {
    if (!documents) return [];
    if (documents.length > 1) {
      return DocumentThumbnails({ documents, onSelected: setSelectedDocument });
    }

    return documents.map((document, i) => {
      return <div className={styles.carouselImage} onClick={() => setSelectedDocument(document)}>
        <Image src={`/api/v1/documents/${document.id}/preview`} alt={`/api/v1/documents/${document.id}/file`} />
      </div>
    });
  }, [ documents ]);

  const onModalClose = () => {
    if (selectedImage !== null) setSelectedImage(null);
    else setSelectedDocument(null);
  }

  return <>
    <Carousel statusFormatter={() => ''} autoPlay infiniteLoop swipeScrollTolerance={64} preventMovementUntilSwipeScrollTolerance={true} showStatus={false}>
      {images}
    </Carousel>
    <Modal enabled={selectedDocument !== null} onClose={onModalClose}>
      {modalView}
    </Modal>
  </>
}

export const ResumeImageCarousel = ({
  resumeId,
  referenceId,
  skills,
  search,
}: {
  resumeId: string;
  referenceId?: string | null;
  skills: string[];
  search?: string;
}) => {
  const documents = useDocuments({
    resumeId,
    statusIn: [
      DocumentStatus.PendingReview,
      DocumentStatus.Approved,
    ],
  });

  const ref = createRef<any>();

  return useMapping(() => {
    if (documents.loading) return <></>
    if (documents.error) return <></>

    const filterSkills: (_: Document[]) => Document[] = (() => {
      if (!skills || skills.length === 0) return xs => xs;

      const skillSet = new Set(skills);
      const includesAny = doc => {
        for (const x of (doc.skills || [])) {
          if (skillSet.has(x)) return true;
        }

        return false;
      }
      return docs => docs.filter(includesAny);
    })();

    const filterReferences = (): (_: Document[]) => Document[] => {
      if (!referenceId) return xs => xs;
      return docs => docs.filter((x: Document) => x.referenceId === referenceId);
    }

    let filtered = filterSkills(
      filterReferences()(
        documents.value
      )
      .filter(x => !!x.skills)
    );

    if (search) {
      filtered = filtered.filter(x => x.description?.toLowerCase()?.includes(search));
    }

    return <DocumentsCarousel documents={filtered} />
  }, [ skills, ref.current, documents.loading, documents.value, search, referenceId ]);
}

export const ResumeColumnA = ({
  resume,
}: {
  resume: Resume;
}) => {
  const [ selectedSkills, setSelectedSkills ] = useState<string[]>(() => []);
  const [ selectedReference, setSelectedReference ] = useState<string | null>(null);
  const [ search, setSearch ] = useState('');

  const onReferenceSelected = (x: VerifiedReference) => {
    if (x.referenceId === selectedReference) setSelectedReference(null);
    else setSelectedReference(x.referenceId);
  }

  const onSkillSelected = (skill: string) => {
    if (selectedSkills.includes(skill)) setSelectedSkills([]);
    else setSelectedSkills([
      skill,
    ]);
  }

  const aboutMe = (() => {
    if (!resume.about) return <></>
    return <Panel>
      <div style={{ margin: '1rem' }}>
        <Text capitalize bold fontSize={FontSize.Large}>About Me</Text>
        <div style={{ marginTop: '.5rem' }} />
        <HorizontalBreak />
        <div style={{ marginTop: '.5rem' }} />
        <SummaryPanel value={resume.about || ''} />
      </div>
    </Panel>
  })();

  const qualifications = (() => {
    if (!resume.summary) return <></>
    return <Panel>
      <div style={{ margin: '1rem' }}>
        <Text capitalize bold fontSize={FontSize.Large}>Summary of Qualifications</Text>
        <div style={{ marginTop: '.5rem' }} />
        <HorizontalBreak />
        <div style={{ marginTop: '.5rem' }} />
        <SummaryPanel value={resume.summary || ''} />
      </div>
    </Panel>
  })();

  const workExperience = (() => {
    if (!resume.workExperience) return <></>;
    return <Panel>
      <div style={{ margin: '1rem' }}>
        <Text capitalize bold fontSize={FontSize.Large}>Work Experience</Text>
        <div style={{ marginTop: '.5rem' }} />
        <HorizontalBreak />
        <div style={{ marginTop: '.5rem' }} />
        <SummaryPanel value={resume.workExperience || ''} />
      </div>
    </Panel>
  })();

  return <div className={styles.column}>
    <ResumeHeader resume={resume} search={search} setSearch={setSearch} selectedReference={!!selectedReference} />
    {aboutMe}
    <Panel>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
        <ResumeImageCarousel
          resumeId={resume.id}
          skills={selectedSkills}
          referenceId={selectedReference}
          search={search}
        />
      </div>
    </Panel>
    <SkillsSection referenceId={selectedReference} resumeId={resume.id} selected={selectedSkills} onSelect={onSkillSelected} />
    <VerifiedReferencesSection
      selected={selectedReference}
      onSelect={onReferenceSelected}
      resumeId={resume.id}
      search={''}
    />
    {qualifications}
    {workExperience}
  </div>
}