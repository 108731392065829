import { Async, useMapping } from "../../hooks"
import { Loading } from "../../ui/control/loading";
import { Icon } from "../../ui/imagery/Icon";
import { Text } from "../../ui";
import { Button } from "../../ui/interactive/button";

export const BackButton = ({
  onClick,
  disabled=false,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  const content = useMapping(() => {
    return <>
      <Icon style={{ margin: 'auto' }} icon="chevron_left" />
      <Text>Back</Text>
    </>
  }, [ onClick ]);

  return <Button disabled={disabled} onClick={onClick}
    style={{ display: 'flex', alignItems: 'center' }}
  >
    {content}
  </Button>
}