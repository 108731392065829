import { Icon } from "../imagery/Icon"
import { Panel } from "../layout/Panel"
import { Button } from "./button"
import { Text } from "../typography"
import { HTMLAttributes } from "react";

export type HotbarProps = HTMLAttributes<HTMLElement>;
export const Hotbar = (props: HotbarProps) => {
  return <Panel style={{ width: '100%', overflowX: 'scroll' }}>
    <div style={{ display: 'inline-flex', width: 'max-content' }} {...props} />
  </Panel>
}