import { AxiosInstance } from "axios";
import { AsyncArgs, useAsync, useHttp, useMapping } from "../hooks";
import { useEffect } from "react";
import useEvents from "../hooks/use-events";
import { Emitter } from "event-emitter";
import { AppError, ErrorCode } from "./errors";

export type Resume = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  contactName: string;
  slug: string | null;
  email: string;
  linkedInUrl: string | null;
  personalUrl: string | null;
  about: string | null;
  summary: string | null;
  workExperience: string | null;
}

export type ResumeInput = {
  contactName: string;
  slug: string | null;
  email: string;
}

export type ResumePatch = {
  slug?: string | null;
  contactName?: string;
  email?: string | null;
  linkedInUrl?: string | null;
  personalUrl?: string | null;
  about?: string | null;
  summary?: string | null;
  workExperience?: string | null;
}

export type ResumeQueryParams = {
  referenceId?: string;
  contactName?: string;
  slug?: string;
  limit?: number;
}

export class ResumesApi {
  constructor (
    private client: AxiosInstance,
    private events: Emitter,
  ) {
    this.client.interceptors.response.use(null, err => {
      if (!err.response) return Promise.reject(err);

      if (!err.response.headers['content-type'].endsWith('/json')) return Promise.reject(err);
      const data = err.response.data;
      if ('code' in data) {
        return Promise.reject(new AppError(data.code, data.issues));
      }

      return Promise.reject(err);
    });
  }

  create = async (input: ResumeInput): Promise<Resume> => {
    return await this.client.post('/api/v1/resumes', input)
      .then(r => r.data)
      .then(resume => {
        this.events.emit('resume-created', resume);
        return resume;
      });
  }

  update = async (resumeId: string, patch: ResumePatch): Promise<Resume> => {
    patch.slug = patch.contactName?.trim()?.toLowerCase()?.replace(/[\W]+/g, '-');
    console.log(patch);

    return await this.client.patch(`/api/v1/resumes/${resumeId}`, patch)
      .then(r => r.data)
      .then(resume => {
        this.events.emit('resume-upated', resume);
        return resume;
      });
  }

  find = async (query: ResumeQueryParams={}) => {
    return await this.client.get(`/api/v1/resumes`, {
      params: query,
    })
      .then(r => r.data as Resume[]);
  }

  get = async (resumeId: string) => {
    return await this.client.get(`/api/v1/resumes/${resumeId}`)
      .then(r => r.data as Resume);
  }

  findOne = async (query: ResumeQueryParams={}): Promise<Resume> => {
    const rows = await this.find({
      ...query,
      limit: 1,
    });
    if (!rows || rows.length === 0) throw new AppError(
      ErrorCode.NotFound,
      [],
    );

    return rows[0];
  }
}

export const useResumesApi = () => {
  const http = useHttp();
  const events = useEvents();

  return useMapping(() => {
    return new ResumesApi(http.client, events);
  }, [ http.client ]);
}

export const useResumes = (query: ResumeQueryParams={}) => {
  const resumesApi = useResumesApi();
  const events = useEvents();
  const task = useAsync(() => resumesApi.find(query), [ ...Object.values(query) ], {
    debounce: true,
    delay: 250,
  });

  useEffect(() => {
    const f = () => task.trigger(true);
    events.on('resume-created', f);
    return () => events.off('resume-created', f);
  }, [ resumesApi ]);

  return task;
}

export const useFindResume = (query: ResumeQueryParams={}) => {
  const resumesApi = useResumesApi();
  const events = useEvents();
  const task = useAsync(async () => {
    return await resumesApi.findOne(query);
  }, [ ...Object.values(query) ]);

  useEffect(() => {
    if (!task.value) return;

    const f = (resume: Resume) => {
      if (resume.id !== task.value.id) return;
      task.setValue(resume);
    };
    events.on('resume-updated', f);
    return () => events.off('resume-updated', f);
  }, [ query, task.value, resumesApi ]);

  return task;
}

export const useResume = (resumeId: string) => {
  const resumesApi = useResumesApi();
  const events = useEvents();
  const task = useAsync(async () => {
    return await resumesApi.get(resumeId);
  }, [ resumeId ]);

  useEffect(() => {
    const f = (resume: Resume) => {
      if (resume.id !== resumeId) return;
      task.setValue(resume);
    };
    events.on('resume-updated', f);
    return () => events.off('resume-updated', f);
  }, [ resumeId, resumesApi ]);

  return task;
}