import { useErrors, useMapping } from "../../hooks";
import { Industry, IndustryQueryParams, useIndustries } from "../../io/industries-api"
import { Loading } from "../../ui/control/loading";

type IndustriesProps = {
  industries: Industry[];
}
type IndustriesComponent<Props> = (props: Props & IndustriesProps) => JSX.Element;

export const IndustriesLoader = <Props,>({
  Component,
  query={},
  ...props
}: {
  Component: IndustriesComponent<Props>;
  query: IndustryQueryParams;
} & Omit<Props, 'industries'>) => {
  const errors = useErrors();
  const task = useIndustries(query);

  return useMapping(() => {
    if (task.loading) return <Loading />
    if (task.error) return errors.onError(task.error);

    return <Component industries={task.value} {...props as any} />
  }, [ task.loading, task.error, task.value ]);
}