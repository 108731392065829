import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAsync, useErrors, useMapping } from "../../hooks";
import { ResumeInput, useResumesApi } from '../../io/resumes-api';
import { FontSize, Grid, Text } from "../../ui";
import { TextInput } from "../../ui/input/TextInput";
import { Button } from "../../ui/interactive/button";
import { Page } from "../../ui/interactive/page";
import { Panel } from "../../ui/layout/Panel";
import { HorizontalBreak } from "../../ui/layout/horizontal-break";

export const CreateResumePage = () => {
  const resumesApi = useResumesApi();
  const [ resumeInput, setResumeInput ] = useState<Partial<ResumeInput>>(() => ({}));
  const nav = useNavigate();

  const setContactName = (contactName: string) => setResumeInput(input => ({
    ...input,
    contactName,
  }));
  const setEmail = (email: string) => setResumeInput(input => ({
    ...input,
    email,
  }));
  const setAbout = (about: string) => setResumeInput(input => ({
    ...input,
    about,
  }));


  const create = useAsync(async () => {
    if (!resumeInput.contactName) throw new Error('expected-contact-name');
    if (!resumeInput.email) throw new Error('expected-email');

    const input = {
      ...resumeInput,
      contactName: resumeInput.contactName.trim(),
      slug: resumeInput.contactName.trim().replace('/\s/g', '-'),
      email: resumeInput.email.trim(),
    }
    const resume = await resumesApi.create(input);
    nav(`/resumes/${resume.id}/edit`, { replace: true });
  }, [ resumeInput ] , {
    defer: true,
    default:null,
  });
  const errors = useErrors();

  const errorView = useMapping(() => {
    if (create.error) return errors.onError(create.error);
    if (create.loading) return <Text center fontSize={FontSize.Medium}>Please wait...</Text>
    return <div />
  }, [ create.loading, create.error ]);

  return <Page>
      <Panel style={{ maxWidth: '30rem', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '1rem' }}>
        <div style={{ marginTop: '1rem' }} />
        <Text center style={{ marginLeft: '1rem' }} fontSize={FontSize.Large} bold>Info</Text>
        <div style={{ marginTop: '1rem' }} />
        <HorizontalBreak />
        <TextInput disabled={create.loading} placeholder={"Contact Name"} maxLength={32}
          setValue={setContactName}
        />
        <TextInput disabled={create.loading} placeholder={"Email"} maxLength={64}
          setValue={setEmail}
        />
        <div style={{ marginTop: '1rem' }} />
        <TextInput disabled={create.loading} area style={{ resize: 'none', height: '10rem' }} placeholder={"About"}
          setValue={setAbout}
         />
        <div style={{ height: '2rem' }}>
          {errorView}
        </div>
        <Button onClick={() => create.trigger()}>
          Create
        </Button>
      </div>
    </Panel>
  </Page>
}