import { useParams } from "react-router-dom"
import { Page } from "../../ui/interactive/page"
import { useDocuments, useDocumentsApi, Document, DocumentStatus } from "../../io/documents-api";
import { useAsync, useErrors, useMapping } from "../../hooks";
import { Loading } from "../../ui/control/loading";
import { Image, Card, Grid, Text } from "../../ui";
import { Button } from "../../ui/interactive/button";
import { Panel } from "../../ui/layout/Panel";
import { ApproveDocumentCard } from "../documents/approve-document-card";

const DocumentCard = ({
  document,
}: {
  document: Document;
}) => {
  return <ApproveDocumentCard document={document} />
}

export const ReferenceReviewSection = ({
  resumeId,
}: {
  resumeId: string;
}) => {
  const errors = useErrors();
  const documents = useDocuments({
    resumeId,
    statusIn: [
      DocumentStatus.PendingReview,
      DocumentStatus.Declined,
    ]
  });

  const documentViews = useMapping(() => {
    if (documents.loading) return <Loading />
    if (documents.error) return errors.onError(documents.error);

    return documents.value.map(document => {
      return <DocumentCard key={`document-${document.id}`} document={document} />
    });
  }, [ documents.loading, documents.error, documents.value ]);

  return <>
    <Panel>
      <div style={{ margin: '1rem' }}>
        <Grid>
          {documentViews}
        </Grid>
      </div>
    </Panel>
  </>
}
