import { Async, useMapping } from "../../hooks"
import { Loading } from "../../ui/control/loading";
import { Icon } from "../../ui/imagery/Icon";
import { Text } from "../../ui";
import { Button } from "../../ui/interactive/button";

export const NewButton = ({
  task,
  disabled=false,
}: {
  task: Async<any>;
  disabled?: boolean;
}) => {
  const onClick = () => {
    if (task.loading) return;
    task.trigger(false);
  }

  const content = useMapping(() => {
    if (task.loading) return <Loading />

    return <>
      <Icon style={{ margin: 'auto' }} icon="add" />
      <Text>New</Text>
    </>
  }, [ task.loading, task.error ]);

  return <Button disabled={disabled} onClick={onClick}
    style={{ display: 'flex', alignItems: 'center' }}
  >
    {content}
  </Button>
}