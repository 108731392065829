import { useAsync, useHttp, useMapping } from "../../hooks"
import { Loading } from "../../ui/control/loading";
import { Page } from "../../ui/interactive/page";
import { Panel } from "../../ui/layout/Panel";

export const TokensPage = () => {
  const http = useHttp();
  const tokens = useAsync(async () =>
    http.client.get('/api/v1/tokens')
    .then(r => r.data)
  , []);

  const view = useMapping(() => {
    if (tokens.loading) return <Loading />
    if (tokens.error) return <></>;

    return tokens.value.map(x => {
      return <pre>
        <code>
          {JSON.stringify(x, null, 2)}
        </code>
      </pre>
    });
  }, [ tokens.loading, tokens.error, tokens.value ]);

  return <Page>
    <Panel>
      {view}
    </Panel>
  </Page>
}