import React from 'react';
import ReactLoading from 'react-loading';

import styles from './loading.module.scss';
import clsx from 'clsx';

export const Loading = ({
  floating=false,
}: {
  floating?: boolean;
}) => {
  return <div className={clsx(styles.loadingWrapper, floating && styles.floating)}>
    <ReactLoading
      type='cubes'
      color='lightgreen'
      height='64px'
      width='128px'
      className={styles.loading}
    />
  </div>
}