import Loading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useAuth, useAsync, loadAuth0, useMapping, useHttp, Auth0Provider } from "../../hooks";
import { Page } from "../../ui/interactive/page";
import { Text } from "../../ui";
import useAuth0 from "../../hooks/use-auth0";
import useEvents from "../../hooks/use-events";

const LogoutLoader = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const http = useHttp();
  const auth0 = useAuth0();
  const events = useEvents();

  const logoutAsync = useAsync(async () => {
    if (!auth.user) {
      navigate('/');
      return;
    }

    events.emit('logout', auth);
    return true;
  }, []);

  return useMapping(() => {
    if (logoutAsync.loading) return <>
      <Text center>Please wait while we log you out.</Text>
      <Loading />
    </>

    return <Loading />
  }, [ logoutAsync.loading ]);
}

export const LogoutPage = () => {
  return <Page>
    <Auth0Provider>
      <LogoutLoader />
    </Auth0Provider>
  </Page>
}