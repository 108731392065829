import { Reference } from '../../io/references-api';
import { Button } from '../../ui/interactive/button';
import { Text } from '../../ui';
import styles from './cards.module.scss';

export const ReferenceContactCard = ({
  reference,
  onClick,
}: {
  reference: Reference;
  onClick: (x: Reference) => void;
}) => {
  return <Button
    className={styles.referenceContactCard}
    onClick={() => onClick(reference)}
  >
    <Text>Contact Name: {reference.contactName}</Text>
    <Text>Phone: {reference.phoneNumber}</Text>
  </Button>
}