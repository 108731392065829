import clsx from 'clsx';
import React, { useLayoutEffect } from 'react';
import styles from './page.module.scss';


export type PageProps = React.HTMLAttributes<HTMLElement>;
export const Page = ({ className, ...props }: PageProps) => {
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  return <div
    className={clsx(
      className,
      styles.page,
    )}
    {...props}
  />
}