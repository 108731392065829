export enum ErrorCode {
  BadRequest = "bad-request",
  NotFound = 'not-found',
  Conflict = 'conflict',
  Forbidden = 'forbidden',
}

export enum IssueCode {
  RequiredField = 'required-field',
  InvalidType = 'invalid-type',
  ExpectedNonNull = 'expected-non-null',
  MaxLengthExceeded = 'max-length-exceeded',
  ServiceDoesNotExist = 'service-does-not-exist',
  ServiceAccessRejected = 'service-access-rejected',
  RecordDoesNotExist = 'record-does-not-exist',
  InvalidField = 'invalid-field',
  AlreadyExists = 'already-exists',
  IncorrectPhoneNumber = 'incorrect-phone-number',
}

export type Issue = {
  issue: IssueCode;
} & Record<string, any>;

export class AppError extends Error {
  constructor(
    public code: ErrorCode,
    public issues: Issue[],
  ) {
    super(code);
  }
}
