import { useMapping } from "../../hooks";
import { Industry } from "../../io/industries-api";
import { Panel } from "../../ui/layout/Panel";
import { ListProps } from "../../ui/layout/list";
import { Text } from "../../ui";
import { Button } from "../../ui/interactive/button";

export const IndustriesList = ({
  industries,
  onSelected,
  ...props
}: {
  industries: Industry[],
  onSelected: (x: Industry) => void;
}) => {
  const items = useMapping(() => {
    console.log(industries);
    return industries.filter(x => x.tag).map(industry => {
      return <li style={{ margin: 'auto', maxWidth: '30rem' }}>
        <Panel onClick={() => onSelected(industry)}>
          <Button flat cover>
            <div style={{ margin: '1rem' }}>
              <Text capitalize>
                {industry.tag}
              </Text>
            </div>
          </Button>
        </Panel>
      </li>
    });
  }, [ industries ]);

  return <ul
    {...props}
  >
    {items}
  </ul>
}