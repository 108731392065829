import React, { createContext, ReactNode, useContext } from 'react';
import useAuth from './use-auth';
import EventEmitter, { Emitter} from 'event-emitter';

const EventsContext = createContext<Emitter>(EventEmitter());

export enum StateType {
  Connecting,
  Failed,
  Closed,
  Open,
}

export type State = {
  type: StateType;
  ws: WebSocket | null;
  error?: string;
}

const emitter = EventEmitter();

export const EventsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <EventsContext.Provider value={emitter}>
    {children}
  </EventsContext.Provider>
}

const useEvents = () => {
  return useContext(EventsContext);
}

export default useEvents;