import { Page } from "../../ui/interactive/page"
import { Panel } from "../../ui/layout/Panel"
import { Grid, Text } from "../../ui"
import { useEffect, useState } from "react";
import { TextInput } from "../../ui/input/TextInput";
import { useAsync, useAuth, useErrors, useHttp, useMapping, useQueryParam } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../ui/interactive/button";
import { useReference } from "../../io/references-api";
import { AppError, IssueCode } from "../../io/errors";

export const ReferenceAuthorizePage = () => {
  const {
    referenceId,
  } = useParams() as {
    referenceId: string;
  };
  const {
    value: token
  } = useQueryParam("token", x => x);

  const reference = useReference(referenceId);

  const [ contactName, setContactName ] = useState('');
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ occupation, setOccupation ] = useState('');

  useEffect(() => {
    if (!reference.value) return;
    setContactName(reference.value.contactName);
    setOccupation(reference.value.occupation || '');
  }, [ reference.value?.contactName ]);

  const http = useHttp();
  const errors = useErrors();
  const nav = useNavigate();

  const auth = useAuth();

  const authorize = useAsync(async () => {
    await http.client.post(`/api/v1/references/${referenceId}/authorize`, {
      contactName,
      token,
      phoneNumber,
      occupation,
    }).then(r => r.data);
    nav(`/references/${referenceId}/review`);
    return true;
  }, [ contactName, phoneNumber, occupation, token ], {
    defer: true,
    default: null,
  });

  const view = useMapping(() => {
    if (auth.user) {
      return <Text style={{ margin: '2rem' }} center>You must logout to authorize using this link.</Text>
    }

    if (authorize.loading) return <Text>Please wait</Text>

    const error = ((error?: Error) => {
      if (error) {
        if (!(error instanceof AppError)) return errors.onError(error);

        if (error.issues.find(x => x.issue === IssueCode.IncorrectPhoneNumber)) {
          return <Text error center>The information provided is incorrect please try another attempt.</Text>
        }

        return errors.onError(error);
      }
      return null;
    })(authorize.error);

    return <div style={{ margin: '1rem' }}>
      <Text center>Please verify the following information.</Text>
      {error}
      <div style={{ marginTop: '1rem' }} />
      <div style={{ margin: 'auto', maxWidth: '40rem' }}>
        <TextInput placeholder='Contact Name' value={contactName} setValue={setContactName} />
        <TextInput placeholder='Phone Number' value={phoneNumber} setValue={setPhoneNumber} />
        <TextInput placeholder='Occupation' value={occupation} setValue={setOccupation} />
        <Grid center>
          <Button onClick={() => authorize.trigger()}>Submit</Button>
        </Grid>
      </div>
    </div>
  }, [ auth.user, authorize.loading, authorize.error, authorize.value, contactName, phoneNumber, occupation ]);

  return <Page>
    <Panel>
      {view}
    </Panel>
  </Page>
}