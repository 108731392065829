import { useState } from "react";
import { useErrors, useMapping } from "../../hooks";
import { Industry, IndustryQueryParams, useIndustries } from "../../io/industries-api";
import { Text } from "../../ui";
import { Loading } from "../../ui/control/loading";
import { Button } from "../../ui/interactive/button";
import { Panel } from "../../ui/layout/Panel";
import { Modal } from "../../ui/modals/modal";

const IndustriesDropdownModal = ({
  tag,
  query,
  setShow,
  setValue,
  onSelected,
}: {
  tag: string | null,
  query: IndustryQueryParams;
  setShow: (x: boolean) => void;
  setValue: (x: Industry) => void;
  onSelected: (x: Industry) => void;
}) => {

  const errors = useErrors();
  const industries = useIndustries(query);
  const options = useMapping(() => {
    if (industries.loading) return <Loading />
    if (industries.error) return errors.onError(industries.error);

    const clicked = (ref: Industry) => () => {
      onSelected(ref);
      setValue(ref);
      setShow(false);
    }

    return industries.value.filter(x => x.tag.trim() !== "").map(industry => {
      return <Panel style={{ height: '3rem' }} onClick={clicked(industry)}>
        <Button flat style={{ width: '100%', height: '100%', margin: 0 }}>
          <Text capitalize>
            {industry.tag}
          </Text>
        </Button>
      </Panel>
    });
  }, [ industries.loading, industries.value ]);
  return <Panel>
    <div style={{ marginTop: '1rem' }} />
    <Text bold center>Select an Industry</Text>
    <div style={{ margin: '1rem' }} />
    {options}
  </Panel>
}

export const IndustriesDropdown = ({
  query,
  tag,
  onSelected,
}: {
  query: IndustryQueryParams;  
  tag: string | null;
  onSelected: (x: Industry) => void;
}) => {
  const [ show, setShow ] = useState(false);
  const [ value, setValue ] = useState<Industry | null>(() => {
    if (tag === null) return null;
    return {
      tag,
    };
  });

  const selectionElem = useMapping(() => {
    if (value === null) {
      return <Text center>(Select industry)</Text>
    }
    return <Text capitalize center>{value.tag}</Text>
  }, [ JSON.stringify(value), show ]);

  const modalView = useMapping(() => {
    if (!show) return <></>
    return <IndustriesDropdownModal
      tag={tag}
      query={query}
      setShow={setShow}
      setValue={setValue}
      onSelected={onSelected}
    />
  }, [ show ]);

  return <>
    <Panel style={{ marginTop: '1rem'  }}>
      <Button flat style={{ margin: 0, width: '100%', height: '100%' }} onClick={() => setShow(true)}>
        {selectionElem}
      </Button>
    </Panel>
    <Modal enabled={show} onClose={() => setShow(false)}>
      {modalView}
    </Modal>
  </>
}