import { AxiosInstance } from "axios";
import { AsyncArgs, useAsync, useHttp, useMapping } from "../hooks";
import { useEffect } from "react";
import useEvents from "../hooks/use-events";
import { Emitter } from "event-emitter";
import { AppError, ErrorCode } from "./errors";

export type Reference = {
  id: string;
  resumeId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  contactName: string;
  phoneNumber: string;
  occupation: string | null;
}

export type ReferenceInput = {
  resumeId: string;
  contactName: string;
  phoneNumber: string;
  occupation: string | null;
}

export type ReferencePatch = {
  occupation?: string | null;
}

export type ReferenceQueryParams = {
  resumeId?: string;
  id?: string;
  limit?: number;
}

export type VerifiedReference = {
  referenceId: string;
  resumeId: string;
  createdAt: string;
  updatedAt: string;
  contactName: string;
  occupation: string | null;
}

export class ReferencesApi {
  constructor (
    private client: AxiosInstance,
    private events: Emitter,
  ) {
    this.client.interceptors.response.use(null, err => {
      if (!err.response) return Promise.reject(err);

      if (!err.response.headers['content-type'].endsWith('/json')) return Promise.reject(err);
      const data = err.response.data;
      if ('code' in data) {
        return Promise.reject(new AppError(data.code, data.issues));
      }

      return Promise.reject(err);
    });
  }

  create = async (input: ReferenceInput): Promise<Reference> => {
    return await this.client.post('/api/v1/references', input)
      .then(r => r.data)
      .then(reference => {
        this.events.emit('reference-created', reference);
        return reference;
      });
  }

  update = async (referenceId: string, patch: ReferencePatch): Promise<Reference> => {
    return await this.client.patch(`/api/v1/references/${referenceId}`, patch)
      .then(r => r.data)
      .then(reference => {
        this.events.emit('reference-updated', reference);
        return reference;
      });
  }

  delete = async (referenceId: string): Promise<Reference> => {
    return await this.client.delete(`/api/v1/references/${referenceId}`)
      .then(r => r.data)
      .then(reference => {
        this.events.emit('reference-updated', reference);
        return reference;
      });
  }

  find = async (query: ReferenceQueryParams={}) => {
    return await this.client.get(`/api/v1/references`, {
      params: query,
    })
      .then(r => r.data as Reference[]);
  }

  get = async (referenceId: string) => {
    return await this.client.get(`/api/v1/references/${referenceId}`)
      .then(r => r.data as Reference);
  }

  findOne = async (query: ReferenceQueryParams={}): Promise<Reference> => {
    const rows = await this.find({
      ...query,
      limit: 1,
    });
    if (!rows || rows.length === 0) throw new AppError(
      ErrorCode.NotFound,
      [],
    );

    return rows[0];
  }
}

export const useReferencesApi = () => {
  const http = useHttp();
  const events = useEvents();

  return useMapping(() => {
    return new ReferencesApi(http.client, events);
  }, [ http.client ]);
}

export const useReferences = (query: ReferenceQueryParams={}) => {
  const referencesApi = useReferencesApi();
  const events = useEvents();
  const task = useAsync(() => referencesApi.find(query), [ ...Object.values(query) ]);

  useEffect(() => {
    const f = () => task.trigger(true);
    events.on('reference-created', f);
    events.on('reference-updated', f);
    return () => {
      events.off('reference-created', f);
      events.off('reference-updated', f);
    }
  }, [ referencesApi ]);

  return task;
}

export const useReference = (referenceId: string) => {
  const referencesApi = useReferencesApi();
  const events = useEvents();
  const task = useAsync(async () => {
    return await referencesApi.get(referenceId);
  }, [ referenceId ]);

  useEffect(() => {
    const f = (reference: Reference) => {
      if (reference.id !== referenceId) return;
      task.setValue(reference);
    };
    events.on('reference-updated', f);
    return () => events.off('reference-updated', f);
  }, [ referenceId, referencesApi ]);

  return task;
}