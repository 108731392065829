import clsx from 'clsx';
import React from 'react';
import styles from './Icon.module.scss';


type Props = {
  icon: string;
  className?: string;
  large?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export const Icon = ({ icon, className, large, onClick, ...props }: Props) => {
  return <i
    {...props}
    onClick={onClick}
    className={clsx(
      'material-icons',
      styles.icon,
      className,
      large && styles.large,
      onClick && styles.clickable,
    )}
  >{icon}</i>
}