import clsx from 'clsx';
import React, { DetailedHTMLProps, forwardRef, ImgHTMLAttributes, useState } from 'react';
import styles from './image.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';


export type ImageProps = {
  objectFit?: 'fill' | 'contain' | 'cover' | 'scale-down';
  shape?: 'round' | 'square';
  fullHeight?: boolean;
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const _shape = (value?: string) => {
  switch (value) {
    case 'round':
      return styles.round;
    default:
  }
}

export const Image = ({ className, shape, objectFit='contain', ref, onClick, fullHeight, ...props }: ImageProps) => {
  const [ loaded, setLoaded ] = useState(false);

  return <LazyLoadImage
    ref={ref}
    alt={""}
    onClick={onClick}
    onLoad={() => setLoaded(true)}
    className={clsx(
      className || styles.image,
      objectFit && styles[objectFit],
      _shape(shape),
      onClick && styles.clickable,
      !loaded && styles.notLoaded,
      fullHeight && styles.fullHeight,
    )}
    {...props as any}
  />

  return <img
    ref={ref}
    alt={""}
    className={clsx(
      styles.image,
      className,
      objectFit && styles[objectFit],
      _shape(shape),
      onClick && styles.clickable,
      !loaded && styles.notLoaded,
      fullHeight && styles.fullHeight,
    )}
    onLoad={() => setLoaded(true)}
    onClick={onClick}
    {...props}
  />
}