import { useState } from "react"
import { useResumes } from "../../io/resumes-api"
import { Grid, VerticalGrid } from "../../ui"
import { TextInput } from "../../ui/input/TextInput"
import { Panel } from "../../ui/layout/Panel"
import { ResumeGrid } from "./resume-grid"
import { useMapping } from "../../hooks"

export const ResumeSearchSection = () => {
  const [ search, setSearch ] = useState("");
  const query = useMapping(() => {
    if (!search) return {};
    return {
      contactName: `like ${search}`,
    }
  }, [ search ]);

  return <Panel>
    <div style={{ margin: '1rem', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <div style={{ maxWidth: '40rem', margin: 'auto' }}>
        <TextInput style={{ maxWidth: '20rem', width: '20rem' }} placeholder="Search" value={search} setValue={setSearch} />
      </div>
      <div style={{ marginTop: '2rem' }} />
      <ResumeGrid linkTo={x => `/resumes/${x.id}/edit`} query={query} />
    </div>
  </Panel>
}